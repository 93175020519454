import React, { useState } from 'react';
import {HStack,Center} from 'native-base'


function EditablePre({ defaultValue, onSave=f=>f }) {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(defaultValue || '');

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    onSave(text);
  };

  const handleInputChange = (event) => {
    setText(event.target.value);
  };

  const handleKeyDown = (event) => {
    // Intercept tab key press
    if (event.key === 'Tab') {
      event.preventDefault();
      const { selectionStart, selectionEnd } = event.target;
      const newText = text.substring(0, selectionStart) + '\t' + text.substring(selectionEnd);
      setText(newText);
    }
  };

  return (
    <div>
      {isEditing ? (
        <div>
          <textarea
            value={text}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            style={{ width: '100%', height: '70vh',overflowY:'scroll', border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}
          />
          <button className='button-62' onClick={handleSaveClick} style={{ marginTop: '5px' }}>Save Content</button>
        </div>
      ) : (
        <div>
          <pre
            contentEditable={false}
            style={{ border: '1px solid #ccc', padding: '10px', borderRadius: '5px',height: '70vh',overflowY:'scroll' }}
            dangerouslySetInnerHTML={{ __html: text }}
          />
          <HStack   alignItems='center' space={0} w='130px' style={{position: 'absolute', top: '5px', right: '5px'}} >

     <button onClick={handleEditClick}  className=" btn   border-0 text-left mr-2 text-white  " style={{
                        textDecoration:'none', width:'100%',height:'48px',textAlign:'left',borderRadius:'0px',
                    }} ><span> Edit Content </span> </button>
              <svg   width="18px" height="18px" viewBox="0 0 32 32" version="1.1"  fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>new</title> <desc>Created with Sketch Beta.</desc> <defs> </defs> <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketchtype="MSPage"> <g id="Icon-Set" sketchtype="MSLayerGroup" transform="translate(-516.000000, -99.000000)" fill="#ffffff"> <path d="M527.786,122.02 L522.414,125.273 C521.925,125.501 521.485,125.029 521.713,124.571 L524.965,119.195 L527.786,122.02 L527.786,122.02 Z M537.239,106.222 L540.776,109.712 L529.536,120.959 C528.22,119.641 526.397,117.817 526.024,117.444 L537.239,106.222 L537.239,106.222 Z M540.776,102.683 C541.164,102.294 541.793,102.294 542.182,102.683 L544.289,104.791 C544.677,105.18 544.677,105.809 544.289,106.197 L542.182,108.306 L538.719,104.74 L540.776,102.683 L540.776,102.683 Z M524.11,117.068 L519.81,125.773 C519.449,126.754 520.233,127.632 521.213,127.177 L529.912,122.874 C530.287,122.801 530.651,122.655 530.941,122.365 L546.396,106.899 C547.172,106.124 547.172,104.864 546.396,104.088 L542.884,100.573 C542.107,99.797 540.85,99.797 540.074,100.573 L524.619,116.038 C524.328,116.329 524.184,116.693 524.11,117.068 L524.11,117.068 Z M546,111 L546,127 C546,128.099 544.914,129.012 543.817,129.012 L519.974,129.012 C518.877,129.012 517.987,128.122 517.987,127.023 L517.987,103.165 C517.987,102.066 518.902,101 520,101 L536,101 L536,99 L520,99 C517.806,99 516,100.969 516,103.165 L516,127.023 C516,129.22 517.779,131 519.974,131 L543.817,131 C546.012,131 548,129.196 548,127 L548,111 L546,111 L546,111 Z" id="new" sketchtype="MSShapeGroup"> </path> </g> </g> </g></svg>
             
</HStack>

        </div>
      )}
    </div>
  );
}

export default EditablePre;
