import React from 'react'
import {VStack,HStack,Divider,Text,Pressable,Center,
Button,ScrollView } from 'native-base'


const RenderConfiguration = ({state={},showContent,setStep,setShowContent})=>{

return (<>
<VStack space={1} mb={8} >
 <Pressable  onPress={e=>{ setShowContent(false); setStep(1)}} ><h6 style={{color:'gold'}}> [ Genre/Sub-Genre ]:</h6></Pressable>
 <HStack space={'50'} alignItems='center' ml={12} > 
<Text  _text={{color:'white.500' }} w="20%" >- Genre</Text> 
<Text  _text={{color:'white.500' }} >{Object.assign([],state.genre).map(e=>e.value).join(", ") || "Not Provided"}</Text> 
 </HStack>  
 <HStack space={'50'} alignItems='center' ml={12} > 
<Text  _text={{color:'white.500' }}  w="20%" >- Sub-Genre</Text> 
<Text  _text={{color:'white.500' }} >{Object.assign([],state.subgenre).map(e=>e.value).join(", ") || "Not Provided"}</Text> 
 </HStack> 
 </VStack>


<VStack space={1} mb={8} >
<Pressable  onPress={e=>{ setShowContent(false); setStep(2)}} ><h6 style={{color:'gold'}}> [ Setting Preferences ]:</h6></Pressable>
 
 <HStack space={'50'} alignItems='center' ml={12} > 
<Text  _text={{color:'white.500' }}  w="20%" >- Locations</Text> 
<Text  _text={{color:'white.500' }} >{Object.assign([],state.location).map(e=>e.value).join(", ") || "Not Provided"}</Text> 
 </HStack> 
 <HStack space={'50'} alignItems='center' ml={12} > 
<Text  _text={{color:'white.500' }}  w="20%" >- Worldbuilding Elements</Text> 
<Text  _text={{color:'white.500' }} >{Object.assign([],state.elements).map(e=>e.value).join(", ") || "Not Provided"}</Text> 
 </HStack> 
 <HStack space={'50'} alignItems='center' ml={12} > 
<Text  _text={{color:'white.500' }}  w="20%" >- Worldbuilding Sub-Elements</Text> 
<Text  _text={{color:'white.500' }} >{Object.assign([],state.subelements).map(e=>e.value).join(", ") || "Not Provided"}</Text> 
 </HStack> 

  <HStack space={'50'} alignItems='center' ml={12} > 
<Text  _text={{color:'white.500' }} w="20%" >- Historical Era</Text> 
<Text  _text={{color:'white.500' }} >{Object.assign([],state.era).map(e=>e.value).join(", ") || "Not Provided"}</Text> 
 </HStack> 
 
 </VStack>




<VStack space={1} mb={8} >
<Pressable  onPress={e=>{ setShowContent(false); setStep(3)}} ><h6 style={{color:'gold'}}> [ Character Preferences ]:</h6></Pressable>
 <HStack space={'50'} alignItems='center' ml={12} > 
<Text  _text={{color:'white.500' }} w="20%" >- Gender</Text> 
<Text  _text={{color:'white.500' }} >{Object.assign({},state["gender"]).value || "Not Provided"} </Text> 
 </HStack>  
 <HStack space={'50'} alignItems='center' ml={12} > 
<Text  _text={{color:'white.500' }}  w="20%" >- Character Traits</Text> 
<Text  _text={{color:'white.500' }} >{Object.assign([],state["Character Traits"]).map(e=>e.value).join(", ") || "Not Provided"}</Text> 
 </HStack> 
 <HStack space={'50'} alignItems='center' ml={12} > 
<Text  _text={{color:'white.500' }}  w="20%" >- Relationships</Text> 
<Text  _text={{color:'white.500' }} >{Object.assign([],state["Relationships"]).map(e=>e.value).join(", ") || "Not Provided"}</Text> 
 </HStack> 
 <HStack space={'50'} alignItems='center' ml={12} > 
<Text  _text={{color:'white.500' }}  w="20%" >- Protagonist Type</Text> 
<Text  _text={{color:'white.500' }} >{Object.assign([],state["Protagonist Type"]).map(e=>e.value).join(", ") || "Not Provided"}</Text> 
 </HStack> 
 </VStack>




<VStack space={1} mb={8} >
<Pressable  onPress={e=>{ setShowContent(false); setStep(4)}} ><h6 style={{color:'gold'}}> [ Plot Preferences ]:</h6></Pressable>
 
 <HStack space={'50'} alignItems='center' ml={12} > 
<Text  _text={{color:'white.500' }}  w="20%" >- Conflict Type</Text> 
<Text  _text={{color:'white.500' }} >{Object.assign([],state["Conflict Type"]).map(e=>e.value).join(", ") || "Not Provided"}</Text> 
 </HStack> 
 <HStack space={'50'} alignItems='center' ml={12} > 
<Text  _text={{color:'white.500' }}  w="20%" >- Plot Twists</Text> 
<Text  _text={{color:'white.500' }} >{Object.assign([],state["Plot Twists"]).map(e=>e.value).join(", ") || "Not Provided"}</Text> 
 </HStack> 
 <HStack space={'50'} alignItems='center' ml={12} > 
<Text  _text={{color:'white.500' }}  w="20%" >- Themes</Text> 
<Text  _text={{color:'white.500' }} >{Object.assign([],state["Themes"]).map(e=>e.value).join(", ") || "Not Provided"}</Text> 
 </HStack> 
 </VStack>




<VStack space={1} mb={8} >
<Pressable  onPress={e=>{ setShowContent(false); setStep(5)}} ><h6 style={{color:'gold'}}> [ Tone and Atmosphere ]:</h6></Pressable>
 
 <HStack space={'50'} alignItems='center' ml={12} > 
<Text  _text={{color:'white.500' }}  w="20%" >- Mood</Text> 
<Text  _text={{color:'white.500' }} >{Object.assign([],state["Mood"]).map(e=>e.value).join(", ") || "Not Provided"}</Text> 
 </HStack> 
 <HStack space={'50'} alignItems='center' ml={12} > 
<Text  _text={{color:'white.500' }}  w="20%" >- Atmosphere</Text> 
<Text  _text={{color:'white.500' }} >{Object.assign([],state["Atmosphere"]).map(e=>e.value).join(", ") || "Not Provided"}</Text> 
 </HStack> 
 <HStack space={'50'} alignItems='center' ml={12} > 
<Text  _text={{color:'white.500' }}  w="20%" >- Pace</Text> 
<Text  _text={{color:'white.500' }} >{Object.assign([],state["Pace"]).map(e=>e.value).join(", ") || "Not Provided"}</Text> 
 </HStack> 
  <HStack space={'50'} alignItems='center' ml={12} > 
<Text  _text={{color:'white.500' }} w="20%" >- Novel Length</Text> 
<Text  _text={{color:'white.500' }} >{Object.assign({},state["Novel Length"]).value || "Not Provided"} </Text> 
 </HStack>  

 </VStack>






<VStack space={1} mb={8} >
<Pressable  onPress={e=>{ setShowContent(false); setStep(6)}} ><h6 style={{color:'gold'}}> [ Story Outline & Narrative Style ]:</h6></Pressable>
 
  <HStack space={'50'} alignItems='center' ml={12} > 
<Text  _text={{color:'white.500' }} w="20%" >- Story Outline</Text> 
<Text  _text={{color:'white.500' }} >{Object.assign({},state["outline"]).value || "Not Provided"} </Text> 
 </HStack>  
 
   <HStack space={'50'} alignItems='center' ml={12} > 
<Text  _text={{color:'white.500' }} w="20%" >- Pacing</Text> 
<Text  _text={{color:'white.500' }} >{Object.assign({},state["pacing"]).value || "Not Provided"} </Text> 
 </HStack>  
 
   <HStack space={'50'} alignItems='center' ml={12} > 
<Text  _text={{color:'white.500' }} w="20%" >- Language Complexity</Text> 
<Text  _text={{color:'white.500' }} >{Object.assign({},state["language_complexity"]).value || "Not Provided"} </Text> 
 </HStack>  
 
    <HStack space={'50'} alignItems='center' ml={12} > 
<Text  _text={{color:'white.500' }} w="20%" >- Narrative Voice</Text> 
<Text  _text={{color:'white.500' }} >{Object.assign({},state["narrative_voice"]).value || "Not Provided"} </Text> 
 </HStack>  


    <HStack space={'50'} alignItems='center' ml={12} > 
<Text  _text={{color:'white.500' }} w="20%" >- Emotional Depth</Text> 
<Text  _text={{color:'white.500' }} >{Object.assign({},state["emotional_depth"]).value || "Not Provided"} </Text> 
 </HStack> 

     <HStack space={'50'} alignItems='center' ml={12} > 
<Text  _text={{color:'white.500' }} w="20%" >- Writing Style</Text> 
<Text  _text={{color:'white.500' }} >{Object.assign({},state["writing_style"]).value || "Not Provided"} </Text> 
 </HStack> 

     <HStack space={'50'} alignItems='center' ml={12} > 
<Text  _text={{color:'white.500' }} w="20%" >- Dialogue Tone</Text> 
<Text  _text={{color:'white.500' }} >{Object.assign({},state["dialogue_tone"]).value || "Not Provided"} </Text> 
 </HStack> 

     <HStack space={'50'} alignItems='center' ml={12} > 
<Text  _text={{color:'white.500' }} w="20%" >- Scene Description</Text> 
<Text  _text={{color:'white.500' }} >{Object.assign({},state["scene_description"]).value || "Not Provided"} </Text> 
 </HStack> 
     <HStack space={'50'} alignItems='center' ml={12} > 
<Text  _text={{color:'white.500' }} w="20%" >- Character Interaction</Text> 
<Text  _text={{color:'white.500' }} >{Object.assign({},state["character_interaction"]).value || "Not Provided"} </Text> 
 </HStack> 

 </VStack>


<VStack space={1} mb={8} >
<Pressable  onPress={e=>{ setShowContent(false); setStep(7)}} ><h6 style={{color:'gold'}}> [ Audience Preferences ]:</h6></Pressable>
 
 <HStack space={'50'} alignItems='center' ml={12} > 
<Text  _text={{color:'white.500' }}  w="20%" >- Target Audience</Text> 
<Text  _text={{color:'white.500' }} >{Object.assign([],state["Target Audience"]).map(e=>e.value).join(", ") || "Not Provided"}</Text> 
 </HStack> 
 <HStack space={'50'} alignItems='center' ml={12} > 
<Text  _text={{color:'white.500' }}  w="20%" >- Genre Expectations</Text> 
<Text  _text={{color:'white.500' }} >{Object.assign([],state["Genre Expectations"]).map(e=>e.value).join(", ") || "Not Provided"}</Text> 
 </HStack> 
 <HStack space={'50'} alignItems='center' ml={12} > 
<Text  _text={{color:'white.500' }}  w="20%" >- Sensitivity</Text> 
<Text  _text={{color:'white.500' }} >{Object.assign([],state["Sensitivity"]).map(e=>e.value).join(", ") || "Not Provided"}</Text> 
 </HStack> 
 

 </VStack>



</>
)

}

export default RenderConfiguration