import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse';
import * as pdfjsLib from 'pdfjs-dist';
import mammoth from 'mammoth';
import './DocumentUploadTabs.css'; // Import the CSS file for styling

import pdfToText from 'react-pdftotext'
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;


const DocumentUploadTabs = ({state={},updateState=f=>f}) => {
  const [activeTab, setActiveTab] = useState(state.activeTab|| 'upload');
  const [documentFileName, setDocumentFileName] = useState(state.documentFileName||'');
  const [manualContent, setManualContent] = useState( '');
  const [documentContent, setDocumentContent] = useState( '');

  const onDrop = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const fileType = file.type;
      setDocumentFileName(file.name); // Set the file name instead of file content
  updateState('documentFileName',file.name);

      if (fileType === 'application/pdf') {
        handlePdfUpload(file);
      } else if (fileType.includes('text') || fileType === 'text/csv') {
        handleTextOrCsvUpload(file);
      } else if (fileType.includes('word') || fileType.includes('application/vnd')) {
        handleDocUpload(file);
      } else {
          handleTextOrCsvUpload(file);
      }
    });
  };

  const handlePdfUpload = async (file) => {

    pdfToText(file)
        .then(text => updateState('content',text) )
        .catch(error => console.error("Failed to extract text from pdf"))



    // const fileReader = new FileReader();
    // fileReader.onload = async function () {
    //   const typedArray = new Uint8Array(this.result);
    //   const pdf = await pdfjsLib.getDocument(typedArray).promise;
    //   let pdfText = '';
    //   for (let i = 1; i <= pdf.numPages; i++) {
    //     const page = await pdf.getPage(i);
    //     const textContent = await page.getTextContent();
    //     const pageText = textContent.items.map(item => item.str).join(' ');
    //     pdfText += `\nPage ${i}: ${pageText}`;
    //   }
    //     updateState('content',pdfText);
    // };
    // fileReader.readAsArrayBuffer(file);
  };

 

    const handleTextOrCsvUpload = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      const text = reader.result;
      if (file.type === 'text/csv') {
        const parsedCsv = Papa.parse(text, { header: true });
        updateState('content',JSON.stringify(parsedCsv.data, null, 2));

      } else {
        updateState('content',text);

      }
    };

    reader.readAsText(file);
  };



const handleDocUpload = (file) => {
  const reader = new FileReader();
  
  reader.onload = async (event) => {
    const arrayBuffer = event.target.result;

    // Use mammoth to convert the array buffer to plain text
    try {
      const result = await mammoth.extractRawText({ arrayBuffer });
      const text = result.value; // Extracted text from the document
      updateState('content',text);  // Assuming setDocumentContent updates the state with the content
    } catch (error) {
      console.error('Error reading DOCX file:', error);
    }
  };

  reader.readAsArrayBuffer(file);  // Read the file as an ArrayBuffer for Mammoth
};

  const handleManualContentChange = (e) => {
    updateState('content',e.target.value);
  };

  const handleTabSwitch = (tab) => {
    setActiveTab(tab);
      updateState('activeTab',tab);
  };

  return (
    <div className="container">
      <div className="tabs">
        <button 
          onClick={() => handleTabSwitch('upload')} 
          className={`tab ${activeTab === 'upload' ? 'active' : ''}`}
        >
          Document Upload
        </button>
        <button 
          onClick={() => handleTabSwitch('manual')} 
          className={`tab ${activeTab === 'manual' ? 'active' : ''}`}
        >
          Manual Input
        </button>
      </div>

      {activeTab === 'upload' && (
        <div className="tab-content">
          <FileDropzone onDrop={onDrop} />
          {documentFileName && (
            <div className="file-info">
              <p className="text-white mt-3" >Uploaded File: <span className="text-success" > {documentFileName} </span></p>
              <p></p>
            </div>
          )}
        </div>
      )}

      {activeTab === 'manual' && (
        <div className="tab-content">
          <textarea
            placeholder="Paste your content here..."
            defaultValue={manualContent}
            onChange={handleManualContentChange}
            rows={10}
            className="textarea"
          />
          {manualContent && (
            <div className="manual-content">
              <h3>Manual Content:</h3>
              <pre>{manualContent}</pre>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const FileDropzone = ({ onDrop }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ['application/pdf', 'text/plain', 'text/csv', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
  });

  return (
    <div  {...getRootProps({ className: 'dropzone' })}>
      <input {...getInputProps()} />
      <svg className="mt-4"  width="64px" height="64px" viewBox="0 -4 32 32" fill="#000000"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>cloud-upload</title> <desc>Created with Sketch Beta.</desc> <defs> </defs> <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" > <g id="Icon-Set"  transform="translate(-464.000000, -1139.000000)" fill="#06577a"> <path d="M489,1161 L472,1161 C472,1161 465.962,1160.38 466,1155 C466.021,1151.96 468.688,1149.18 472,1149 C472,1144.86 474.65,1141 479,1141 C482.433,1141 484.723,1143.1 485.538,1146.01 C490.493,1145.8 493.844,1149.72 494,1153 C494.135,1155.83 491.68,1159.48 489,1161 L489,1161 Z M487.067,1144.03 C485.599,1141.05 482.543,1139 479,1139 C474.251,1139 470.37,1142.68 470.033,1147.34 C466.542,1148.34 464,1151.39 464,1155 C464,1159.26 467.54,1162.73 472,1162.98 L489,1163 C492.437,1161.51 496,1157.35 496,1153.5 C496,1148.44 492.049,1144.32 487.067,1144.03 L487.067,1144.03 Z M480.777,1145.28 C480.568,1145.07 480.29,1144.98 480.016,1145 C479.741,1144.98 479.464,1145.07 479.254,1145.28 L473.313,1151.19 C472.921,1151.59 472.921,1152.22 473.313,1152.61 C473.707,1153 474.344,1153 474.736,1152.61 L479,1148.37 L479,1157.99 C479,1158.54 479.447,1158.99 480,1158.99 C480.553,1158.99 481,1158.54 481,1157.99 L481,1148.34 L485.295,1152.61 C485.688,1153 486.325,1153 486.718,1152.61 C487.11,1152.22 487.11,1151.59 486.718,1151.19 L480.777,1145.28 L480.777,1145.28 Z" id="cloud-upload" > </path> </g> </g> </g></svg>
      <p className="mt-4">Drag & drop a document here, or click to select files</p>
      <p className="mb-4">(<span className="text-primary">Accepted formats </span>: PDF, TXT, DOCX, CSV)</p>
    </div>
  );
};

export default DocumentUploadTabs;
