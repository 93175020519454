import React from 'react'
import { Link,useHistory  } from 'react-router-dom'
import {VStack,HStack,Divider,Text,Pressable,Stack,InputLeftElement,Input,Center,
Button,ScrollView,useMediaQuery } from 'native-base'
import {setUser,getUser} from '../../helper/authentication'
import step_process,{generate_scenes} from '../../assets/steps_process'
import genresandsubgenres from '../../assets/genresandsubgenres'
import preferences from '../../assets/preferences'
import outline_options from '../../assets/outline_options'
import styles_options from '../../assets/styles_options'
import scenes_formats from '../../assets/scenes.json'
import '../../App.css'
import Select from "react-select";
import Steps from 'react-steps';
import RenderConfiguration from '../settings_view'
import EditablePre from '../editable_pre'
import FileUpload from '../file_upload'
import HtmlViewer from '../HtmlViewer';
import {Banner300by250,Banner728by90} from "../Banner";
const { GoogleGenerativeAI } = require("@google/generative-ai");

const defaultStages = {
        0:{status:'start',title:'Summarization Options',  result:"configuration",type:'string'},
        1:{status:'start',title:'AI Processing Options',  result:"processing",type:'string'},
        2:{status:'start',title:'Content Result',  result:"content",type:'string'}, 
        // 2:{status:'start',title:'Introduction Details',result:""},
        // 3:{status:'start',title:'More on Chapter Content',result:""},
        // 4:{status:'start',title:'Paragraph Content',result:""},
        // 5:{status:'start',title:'Reference and Citation',result:""},
        // 6:{status:'start',title:'Book Blurb',result:""},
        // 7:{status:'start',title:'Author Bio and Acknowledgments',result:""},
        }

const defaultScenes = [ 
    ] 



export default function SignInPage() {
     const [show, setShow] = React.useState(false);
     const [isLoading,setIsLoading] = React.useState(false);
     const [step, setStep] = React.useState(1);
     const [currentStage, setCurrentStage] = React.useState();
     const [done, setDone] = React.useState(false);
     const [stages, setStages] = React.useState(defaultStages);
     const [state, setState] = React.useState({
    "summary_length": {
        "value": "Brief (Highlight the key points while keeping it concise and focused)",
        "label": "Brief (Highlight the key points while keeping it concise and focused)"
    },"summary_tone_style": {
        "value": "Formal (For formal or technical documents.)",
        "label": "Formal (For formal or technical documents.)"
    },  "summary_focus": {
        "value": "Key Themes (Highlight the main topics and group related information under each theme)",
        "label": "Key Themes (Highlight the main topics and group related information under each theme)"
    },
    "summary_language": {
        "value": "Auto Detect",
        "label": "Auto Detect"
    },
    "summarization_model": {
        "value": "Extractive (Summarize by selecting key sentences without altering the original language.)",
        "label": "Extractive (Summarize by selecting key sentences without altering the original language.)"
    },
});
     const [showContent, setShowContent] = React.useState(false);
     const [showContentKey, setShowContentKey] = React.useState({title:'',key:""});
     const [chapters, setChapters] = React.useState(defaultScenes);
     const [summariseScenes, setSummariseScenes] = React.useState(defaultScenes);
     const [chapter, setChapter] = React.useState(1);
     const [scene, setScene] = React.useState(1);
     const [loading_label, setLoadingLabel] = React.useState("Summarizing Content" );



const history = useHistory();


const step_json = [
    {step:1,label:'Content Upload'},
    {step:2,label:'Summarization Options'},
    {step:3,label:'AI Processing Options'}, 
    {step:4,label:'Result'},
    ].map(e=>({
        "text": e.label,
        "isActive": e.step===step,
        "isDone": e.step<step
    }))


React.useEffect(()=>{
  localStorage.setItem('step',step||1)
  if (step===7){
    update_stages(0,'done')
  }
},[step]) 

React.useEffect(()=>{



     const selectedId =  localStorage.getItem('historyview') 
     if (selectedId){
const storedHistory = JSON.parse(localStorage.getItem('history')) || {};
 const content = storedHistory[selectedId];
    if (content) {
setState(content)
 setStep(4)

    } else {

 const sp = localStorage.getItem('step')||1
 if (sp){
     setStep(Number(sp))
 } else {
    setStep(1)
 }

    }

     }
  





},[]) 








const MAX_TOKENS = process.env.MAX_TOKENS || 40000;  // Adjust based on Gemini's prompt size limit
const genAI = new GoogleGenerativeAI(process.env.API_KEY||'AIzaSyBHA471zD6V7c-JEK3qcgBWvyM36w4wO6c');
const model = genAI.getGenerativeModel({ model: process.env.API_VERSION || "gemini-1.5-flash"});



  const updateState = (label,value) => {
setState(prev=>({...prev,[label]:value}))
  }




 // Function to split the text into paragraphs or semantic chunks
  function splitTextIntoParagraphs(text, maxTokens) {
    setLoadingLabel("Splitting content into parts...",text)
    const paragraphs = text.split('\n\n');  // Assuming paragraphs are separated by two newlines
    const parts = [];
    let currentPart = '';

    for (const paragraph of paragraphs) {
      if ((currentPart + paragraph).length > maxTokens) {
        parts.push(currentPart);  // Push current part when limit is reached
        currentPart = '';  // Start a new part
      }
      currentPart += paragraph + '\n\n';  // Add paragraph to the current part
    }

    if (currentPart.trim()) {
      parts.push(currentPart);  // Push the last part
    }

    return parts;
  }








  // Function to recursively summarize text parts
  async function recursiveSummarize(parts) {
    // First pass: Summarize each chunk individually
    const summarizedParts = [];
    const part_num = 1
    for (const part of parts) {
          setLoadingLabel(`Summarizing content part ${part_num} of ${parts.length} ...`)
      const summary = await handleGeminiRequest(part);
      if (summary) {
        summarizedParts.push(summary);
      }
    }

    setLoadingLabel('Processing and merging contents')

    // If the summary itself is too long, split and summarize recursively
    const combinedSummary = summarizedParts.join(' ');  // Combine the summaries
    if (combinedSummary.length > MAX_TOKENS) {
      const secondPassParts = splitTextIntoParagraphs(combinedSummary, MAX_TOKENS);
      return await recursiveSummarize(secondPassParts);  // Recursive summarization
    }

    return combinedSummary;  // Return final summary if small enough
  }







//   const handleGeminiRequest = (name,prompt,callback=f=>f) => {
//     setIsLoading(true);
//     console.log(name,prompt)
 
//     const result = model.generateContent(prompt).then(
//         response => {
//             setIsLoading(false);
// // console.log(response)
// console.log(response.response['candidates'][0]['content']['parts'][0].text)

//  // callback(response)

//         }).catch(error => {
//                 setIsLoading(false);
//                 console.error("Error:", error)
//             });


 
//   } 


 
 const handleGeminiRequest = async (text) => {
    try {
        setIsLoading(true);  // Start loading

        const result = await model.generateContent(generate_scenes( {...state,content:text })   );
        setIsLoading(false);
        const generatedText = result.response['candidates'][0]['content']['parts'][0].text;
        console.log(generatedText);
        return generatedText;

    } catch (error) {
        setIsLoading(false);
        console.error("Error:", error);
        throw error;  // Optionally throw the error to handle it outside this function
    }
};






const getChapter = (ch)=> ch.indexOf("-") >= 0 ? ch.split("-"):['1','1']

const goback=()=>setStep(step-1)

const reGenerate=(key)=>{
// 
}

const update_stages=(key=0,status='done')=>{
    setCurrentStage(key)
     const new_stages = {...stages}
        new_stages[String(key)].status = status
        setStages(new_stages)
}


function removeConsecutiveBr(inputText) { 
   return inputText.replace(/(\*+\s*[^:]+\:\s*\*+)/g, (match) => {
        // Extract the text without the asterisks
        const text = match.replace(/\*\*/g, '');
        return `<br/><strong>${text}</strong>`;
    });
}


const goforward = async (m_step) => {
    if (m_step || step == 3) {
  
          try {
 
    const initialParts = splitTextIntoParagraphs( state.content, MAX_TOKENS);
  const content = await recursiveSummarize(initialParts);

 
  setLoadingLabel('Summarization completed')
        updateState('summarize_content', removeConsecutiveBr(content));
                setStep(4);

            // update_stages(3, 'progress');
          // console.log(state)
            // handleGeminiRequest('summarize_content', generate_scenes( state), (content) => {
          
              
            // });

        
        } catch (error) {
            console.error("Error:", error);
        }
    } else {
        if (!state.documentFileName){
            alert("Provide content to continue")
        } else {

        setStep(step + 1);
        }
    }
};


const deleteContent = (id) => {
  const history = JSON.parse(localStorage.getItem('history')) || {};
  if (history[id]) {
    delete history[id];
    localStorage.setItem('history', JSON.stringify(history));
  } 
};





const renderHome=()=>{
    return (
        <>
<Center mt={-2}  h={'90vh'}>
 
    <button  className="danger-buttons button-63" onClick={e=>setStep(step-1)} style={{
                        textDecoration:'none',width:200,height:'48px',borderRadius:'17px'
                    }} ><span>Home </span> </button>
                   
</Center>

</>)
}



const RenderDropdown=({label="Choose your color", value="",setValue=f=>f,optionList=[],
    placeholder="Select Option(s)",isMulti=false})=>{

const [isLandScape, isPortrait] = useMediaQuery([{
    orientation: "landscape"
  }, {
    orientation: "portrait"
  }]);



return (
 <div className="app" >
<Text   style={{fontSize:"1.3rem", }} >{label}:</Text>
     <div className="dropdown-container mt-3">
      <Select
        options={optionList}
        placeholder={placeholder}
        value={value}
        onChange={setValue}
         isSearchable={isPortrait ? false: true}
         isMulti={isMulti}
      />
    </div>
    </div>
    )

}

const RenderLoading=({label=""})=>{
    return (
        <>
<VStack mt={3}    alignItems='center' justifyContent='center'  >
 

   <svg    
style={{    display: "block", shapeRendering: "auto",}} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
<g transform="translate(50 42)">
  <g transform="scale(0.8)">
    <g transform="translate(-50 -50)">
      <polygon fill="#e15b64" points="72.5 50 50 11 27.5 50 50 50">
        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 38.5;360 50 38.5" keyTimes="0;1"></animateTransform>
      </polygon>
      <polygon fill="#f8b26a" points="5 89 50 89 27.5 50">
        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 27.5 77.5;360 27.5 77.5" keyTimes="0;1"></animateTransform>
      </polygon>
      <polygon fill="#abbd81" points="72.5 50 50 89 95 89">
        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 72.5 77.5;360 72 77.5" keyTimes="0;1"></animateTransform>
      </polygon>
    </g>
  </g>
</g>
 </svg> 
 <p style={{color:'white'}} > {label} </p>
                   
</VStack>

</>)
}


const renderStepOne=()=>{


    return (
        <>
 
<VStack alignItems='center'     w='100%'     >
<Divider />
    <Text mt={4}  style={{fontSize:"1.5rem", marginBottom:'10px', textAlign:'center' }}> Upload Content for Instant Summarization</Text>
 <FileUpload updateState={updateState} />
</VStack>

</>)
}




const renderStepTwo=()=>{

   


    return (
        <>
<VStack alignItems='center'    >


<VStack space={12}  w={ isPortrait ?'95%': '95%'} >
<VStack space={2} alignItems='center'   >
<Text className='mt-1 text-center ' fontWeight='bold'   style={{fontSize:"1.5rem", textAlign:'center' }}   >Customize Summarization Options </Text>
</VStack>

<RenderDropdown optionList={preferences['Summary Length'].map(e=>({value:e,label:e})) } label="Choose Summary Length" setValue={e=>updateState('summary_length',e)}  value={state.summary_length} />

{{...state.summary_length}.value === 'Custom' ?
<RenderDropdown optionList={preferences['Custom Summary Length'].map(e=>({value:e,label:e})) } label="Choose Custom Length" setValue={e=>updateState('custom_summary_length',e)}  value={state.custom_summary_length} />

:
<></>}


<RenderDropdown optionList={preferences['Summary Focus'].map(e=>({value:e,label:e})) } label="Choose Summary Focus" setValue={e=>updateState('summary_focus',e)}  value={state.summary_focus} />

<RenderDropdown optionList={preferences['Language'].map(e=>({value:e,label:e})) } label="Choose Language" setValue={e=>updateState('summary_language',e)}  value={state.summary_language} />


</VStack>
</VStack>

</>)
}

const renderStepThree=()=>{
    return (
        <>
<Center   >

<VStack space={12}  w={  '95%' } mt={2}  >
<Text className='mt-1' fontWeight='bold'   style={{fontSize:"1.5rem", textAlign:'center'}}   >Customize Processing Options </Text>
 


<RenderDropdown optionList={preferences['Summary Model'].map(e=>({value:e,label:e})) } label="Choose Summarization Model" setValue={e=>updateState('summarization_model',e)}  value={state.summarization_model} />
 

<RenderDropdown optionList={preferences['Summary Tone'].map(e=>({value:e,label:e})) } label="Choose Summary Tone Style" setValue={e=>updateState('summary_tone_style',e)}  value={state.summary_tone_style} />
 

</VStack>
</Center>

</>)
}




const renderStepFour=()=>{
    return (
        <>


<Center   >


<VStack space={4}  w={'90%'} >
<Text className='mt-1'   style={{fontSize:"1.5rem",textAlign:'center'}}   >Result</Text>
 
 <HtmlViewer htmlContent={state.summarize_content} state={state} />
  

</VStack>
</Center>

</>)
}

 


const RenderSideContent=({})=>{
    return (
        <>

        <ScrollView     h='88vh' style={{borderRight:'1px solid silver'}}  >
        <HStack justifyContent='space-between' alignItems='center' pr={3}  >
 
                    <button onClick={e=>{
                        setStep(1)
                        setStages(defaultStages)
                        setState({})
                    }} className=" btn   border-0 text-left mr-2 text-white  " style={{
                        textDecoration:'none', width:'100%',height:'48px',textAlign:'left',borderRadius:'0px',
                    }} ><span> <span className='mr-2' style={{fontSize:'19px'}}>+ </span> New Novel </span> </button>
             
 

</HStack>
         {Object.values(stages).map((stage,key)=>(
            <Pressable _hover={{color:"red.300"}}   onPress={e=>{
                console.log(showContentKey.key,key)
                if ( showContentKey.key === ""){
                setShowContent(true)
                } else if (showContentKey.key === key ){
                setShowContent(!showContent)
                } else {
                setShowContent(true)

                }
                setShowContentKey({title:stage.title,key:key})
}
                 } >
<HStack justifyContent='space-between' pl={6} pr={4} py={4} bg={ (showContentKey.title == stage.title && showContent)  ? 'blue.900':  "dark.100"}>
<Text  _hover={{color:'red.700'}} _text={{color:'white.500',fontSize:'17px'}} >{stage.title} {key===5 && state.Length ? `CH( ${scenes_formats.formats[state.Length.value].chapters} )`:""} {key===5 && state.Length ? `SC-CH( ${scenes_formats.formats[state.Length.value].scenes_per_chapter} )`:""} </Text>

{/*
    stage.status === 'done' ? 
     <span style={{background:'green',fontSize:'10px',color:'white',padding:2,paddingRight:4,paddingLeft:4,borderRadius:'4px',width:'80px',textAlign:'center'}} >
     COMPLETE <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M8.00011 13L12.2278 16.3821C12.6557 16.7245 13.2794 16.6586 13.6264 16.2345L22.0001 6" stroke="#ffffff" stroke-width="2" stroke-linecap="round"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M11.1892 12.2368L15.774 6.63327C16.1237 6.20582 16.0607 5.5758 15.6332 5.22607C15.2058 4.87635 14.5758 4.93935 14.226 5.36679L9.65273 10.9564L11.1892 12.2368ZM8.02292 16.1068L6.48641 14.8263L5.83309 15.6248L2.6 13.2C2.15817 12.8687 1.53137 12.9582 1.2 13.4C0.868627 13.8419 0.95817 14.4687 1.4 14.8L4.63309 17.2248C5.49047 17.8679 6.70234 17.7208 7.381 16.8913L8.02292 16.1068Z" fill="#ffffff"></path> </g></svg>
     </span> 
:     stage.status === 'progress' ? 
<svg   width="18px" height="18px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid"><g><path d="M50 15A35 35 0 1 0 74.74873734152916 25.251262658470843" fill="none" stroke="#ffffff" stroke-width="12"></path><path d="M49 3L49 27L61 15L49 3" fill="#ffffff"></path><animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform></g></svg>
:
 <span style={{background:'gold',fontSize:'10px',color:'black',padding:2,paddingRight:4,paddingLeft:4,borderRadius:'4px',width:'80px',textAlign:'center'}} >PENDING</span>

*/}


</HStack>

 
{key===5 ? chapters.map((scene,key)=>(

<>
 
<VStack style={{borderBottom:'1px solid gray'}}  key={key} justifyContent='space-between' space={1} pl={12} pr={4} py={1}  bg={ (showContentKey.title == stage.title && showContent)  ? 'blue.900':  "dark.300"}>

<Text  _hover={{color:'red.700'}} _text={{color:'white.500',fontSize:'17px'}} >Chapter {scene.chapter}: {scene.chapter_title} </Text>
<Text  _hover={{color:'red.700'}} _text={{color:'white.500',fontSize:'17px'}} >Scene {scene.scene}  </Text>


</VStack>
</>

) ):<></>}

 
<Divider />
</Pressable>

            ))}

        </ScrollView>


</>)
}




const RenderTextContent=({label="",key=""})=>{


return ( 
<VStack  >
<Pressable onPress={e=>setShowContent(false)} >
<Center  > 
<HStack alignItems='center' space={8} >
<h4 style={{color:"white"}}><span  style={{fontSize:'28px',color:'white',marginRight:'13px',fontWeight:'bold'}}> 
<svg fill="#FFFFFF" width="24px" height="24px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M222.927 580.115l301.354 328.512c24.354 28.708 20.825 71.724-7.883 96.078s-71.724 20.825-96.078-7.883L19.576 559.963a67.846 67.846 0 01-13.784-20.022 68.03 68.03 0 01-5.977-29.488l.001-.063a68.343 68.343 0 017.265-29.134 68.28 68.28 0 011.384-2.6 67.59 67.59 0 0110.102-13.687L429.966 21.113c25.592-27.611 68.721-29.247 96.331-3.656s29.247 68.721 3.656 96.331L224.088 443.784h730.46c37.647 0 68.166 30.519 68.166 68.166s-30.519 68.166-68.166 68.166H222.927z"></path></g></svg> </span>  {label}  </h4> 
{String(showContentKey.key) !== '0' ? <Button mb={1} onPress={e=>reGenerate(showContentKey.key)} > Re-Generate</Button>:<> </>}

</HStack>
 </Center>
 </Pressable>
<Divider mb={6}  />

 
{ 
    String(showContentKey.key) === '0' ?

<RenderConfiguration state={state} showContent={showContent} 
setStep={setStep} setShowContent={setShowContent}  />
    :

state[stages[Number(showContentKey.key)].result] ? <EditablePre 
defaultValue={state[stages[Number(showContentKey.key)].result]} onSave={content=>{
    updateState(stages[Number(showContentKey.key)].result,content)
    update_stages(Number(showContentKey.key),'done')
}} ></EditablePre>
: <EditablePre onSave={content=>{
    updateState(stages[Number(showContentKey.key)].result,content)
        update_stages(Number(showContentKey.key),'done')
}} 
defaultValue={`No ${label}, Add/Edit manually or Re-Generate` }> </EditablePre>
}


</VStack>
)



}




const getButtonName=()=> step === 3 ? "Summarize":'Next'
const getLoadingName=()=> (String(currentStage) === '1' || String(currentStage) === '2') ?  "Title & Story Premise ..." : String(currentStage) === '3' ? "Back Cover Blub" :String(currentStage) === '4' ? "Story Introduction": String(currentStage) === '5' ? `Chapter ${chapter} and scene ${scene}` :("AI Loading ... "+currentStage)
const historyl = useHistory()

const [isLandScape, isPortrait] = useMediaQuery([{
    orientation: "landscape"
  }, {
    orientation: "portrait"
  }]);


    return (  
    <HStack minHeight='89vh' justifyContent='space-between'  >
 
    <VStack space={3}  mx={ isPortrait ? 0: 8} w={ isPortrait ? "100%": "92%"}>
     <Steps items={step_json} type={'point'} flat={true}/>
  

  <div>
  <div style={{ display: showContent ? 'block' : 'none', }}>
    <RenderTextContent label={showContentKey.title} key={showContentKey.key} />
  </div>

  <div style={{ display: !showContent && isLoading ? 'block' : 'none' }}>
    <RenderLoading label={loading_label} />
  </div>

  <div style={{ display: !showContent && !isLoading && step === 1 ? 'block' : 'none' }}>
    {renderStepOne()}
  </div>

  <div style={{ display: !showContent && !isLoading && step === 2 ? 'block' : 'none' }}>
    {renderStepTwo()}
  </div>

  <div style={{ display: !showContent && !isLoading && step === 3 ? 'block' : 'none' }}>
    {renderStepThree()}
  </div>

  <div style={{ display: !showContent && !isLoading && step === 4 ? 'block' : 'none' }}>
    {renderStepFour()}
  </div>

  <div style={{ display: !showContent && !isLoading && step === 0 ? 'block' : 'none' }}>
    {renderHome()}
  </div>
</div>



{isLoading ? <></>:
<Center  style={{zIndex:-10}}>
<HStack w='90%'  space={5} mt={3} mb={16} justifyContent='space-around' alignItems='center' >

  {step > 1  && !showContent ?  <button  className="danger-buttons button-63" onClick={e=>goback()} style={{
                        textDecoration:'none',width:200,height:'48px',borderRadius:'17px'
                    }} ><span>Back </span> </button>:<></>}
                   

        {!showContent ?  step != 4 &&     <button    className="primary-buttons button-63" onClick={e=>goforward()} style={{
                        textDecoration:'none' ,height:'48px',borderRadius:'17px'
                    }} ><span>{getButtonName()} <span>&#187;</span></span> </button>:<></>}


 {  step == 4 &&  <>

{
    isPortrait ? 

 <VStack space={4} >      <button    className="primary-buttons button-63" onClick={e=>{
 deleteContent(String(state.content).replace(/<\/?[^>]+(>|$)/g, "").slice(0,40))
    goforward(3)
 }} style={{
                        textDecoration:'none',minWidth:200,height:'48px',borderRadius:'17px'
                    }} ><span>Re-Summarize<span>&#187;</span></span> </button> 


                     <button    className="primary-buttons button-63" onClick={e=>{

setStep(1)
setShow(false)
setIsLoading(false)
setState({}) 
localStorage.removeItem('historyview') 
 historyl.push('/build')

 }} style={{
                        textDecoration:'none',minWidth:200,height:'48px',borderRadius:'17px'
                    }} ><span>New <span>&#187;</span></span> </button> 


</VStack>


:
 <HStack space={4} >      <button    className="primary-buttons button-63" onClick={e=>{
 deleteContent(String(state.content).replace(/<\/?[^>]+(>|$)/g, "").slice(0,40))
    goforward(3)
 }} style={{
                        textDecoration:'none',minWidth:200,height:'48px',borderRadius:'17px'
                    }} ><span>Re-Summarize<span>&#187;</span></span> </button> 


                     <button    className="primary-buttons button-63" onClick={e=>{

setStep(1)
setShow(false)
setIsLoading(false)
setState({}) 
localStorage.removeItem('historyview') 
 historyl.push('/build')

 }} style={{
                        textDecoration:'none',minWidth:200,height:'48px',borderRadius:'17px'
                    }} ><span>New <span>&#187;</span></span> </button> 


</HStack>
}

 </>






                }


      </HStack>
      </Center>
  }
 {isLoading ?  <Banner300by250 /> : <Banner728by90 />}

      </VStack>
 </HStack>
   
    )
}



















//   <Divider />
//         <HStack>





//         <VStack w='80%' >
//         {done ?
// <Center> {stages[step].result}  </Center>
//          :
//         <Center h='60vh' mt={3} >
// <h3 className='main-title'  >Generating {stages[step].title} </h3>
{<></>}
 


// </Center>
// }
//         </VStack>
//         </HStack>