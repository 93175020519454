
const default_prompt = `Now you are Pye Gpt: A Documentation Search Engine, designed to assist users in finding information within our extensive documentation. Your goal is to help users discover relevant topics and solutions based on their search queries.

Instructions:
1. Users will enter search queries, which can be questions, keywords, or topics related to PYE.
2. Your responses should provide accurate and concise information from our documentation.
3. Prioritize offering solutions, code examples, and step-by-step guides where applicable.
4. If a query has multiple relevant results, you can provide a brief summary and offer to provide more detailed information upon request.
5. If a query has no relevant results, say I don't know and tell them which links to go, if available.
6. Your objective is to enhance the user experience by promptly connecting them with the information they need.

Example Queries:
1. How do I install PYE?
2. What features are available in PYE?
3. Provide an example of sentiment analysis using PYE.
4. How can I integrate PYE with my web application?
5. Troubleshoot common errors when using PYE.

Your responses should be informative, helpful, and presented in a language understandable to users.`

const data = {

collections:{
	data:	[
{'name':"Mock Data Generation",id:"124",created:"07 Jun 2023, 10:52 PM"},
{'name':"New Connection",id:"12"},
{'name':"Performance Testing",id:"1"},
{'name':"RadarPro API Testing",id:"3"},  

		],
	type:	'',
	loading:false,
},
prompts:{
	data:	[
{'name':"API Key Check",id:"124",type:'Auto',collection:"124",llm:"GPT",model:"Turbo-3",prompt:default_prompt},
{'name':"Search User",id:"12",type:'Auto',collection:"124",llm:"llamda",model:"llamda 1",prompt:default_prompt},
{'name':"Sanctioned List Detailed",id:"1",type:'Auto',collection:"1",llm:"GPT",model:"GPT-4v1",prompt:default_prompt},
{'name':"Verify Dream User",id:"2",type:'AutoAuto',collection:"124",llm:"GPT",model:"Turbo-3",prompt:default_prompt},  
{'name':"Update Existing Entry",id:"3",type:'Auto',collection:"3",llm:"llamda",model:"llamda 2",prompt:default_prompt},  
		],
	type:	'',
	loading:false,
}
,selected_collection:{
	value:	null,
},selected_prompt:{
	value:	null,
},
// accounts:{
// 	array:	[],
// 	type:	'',
// 	loading:false
// },
// modal:{
// 	data:{},
// 	active:false,
// 	type:'',
// 	id:''
// },image_modal:{
// 	data:[],
// 	active:false,
// },iframe_modal:{
// 	url:'',
// 	active:false,
// },knowledge_modal:{
// 	data:[],
// 	active:false,
// },account_modal:{
// 	data:[],
// 	active:false,
// 	type:'',
// 	id:''
// },
// map:{
// 	open:false,
// 	search:''
// },
// docs:{
// 	data:{
// 		customers:[],
// 		accountDetails:[],
// 		accountDailyBalance:[],
// 		customerEmployer:[],
// 		customerNextOfKin:[],
// 		customerRisk:[],
// 		customerSignatory:[],
// 		customerStatus:[],
// 		signatoryIdentity:[],
// 		permits:[],
// 		signatoryTelephone:[],
// 	},
// 	loading:false
// },
// docRow:{
// 	data:{
// 		customers:{},
// 		accountDetails:{},
// 		accountDailyBalance:{},
// 		customerEmployer:[],
// 		customerNextOfKin:[],
// 		customerRisk:[],
// 		customerSignatory:{},
// 		customerStatus:{},
// 		signatoryIdentity:[],
// 		permits:{},
// 		signatoryTelephone:[],
// 	},
// 	type:''
// },
// edit:{
// 	status:false,
// 	start_edit:false,	
// 	user:{},
// 	content:{},
// 	nodeName:'',
// }

}


export default data