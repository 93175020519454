import React from 'react'
import logo from "./logo.svg";
import {
  Box,
  Image,
  Text,
  Link,
  HStack,
  Heading,
  Switch as ToggleSwitch,
  useColorMode,
  VStack,Divider,Menu,Pressable,Input,Button,useMediaQuery
} from "native-base";
import { Provider } from 'react-redux';
import useStore from './redux-store';
import { PersistGate } from 'redux-persist/integration/react';

import { BrowserRouter as Router, Switch, Route,useHistory,Redirect  } from 'react-router-dom'

import LandingPage from './components/pages/LandingPage'
import LoginPage from './components/pages/LoginPage'
import BuildPage from './components/pages/BuildPage'
import RegisterPage from './components/pages/RegisterPage'
import ForgetPasswordPage from './components/pages/ForgetPasswordPage'
import HomePage from './components/pages/HomePage'
import {LoginRequired,clearUser,SavedPageHistory,getUser} from './helper/authentication'
import { Helmet } from 'react-helmet';


import './App.css'
import './Circle.css'
import Logo from './assets/images/logo-white.png'
// import LogoBlack from './assets/images/logo-black.png'
import LogoColor from './assets/images/logo-color.png'


const LogOut = ({verifyLoggedIn})=>{

const history = useHistory()

React.useEffect( () =>{

clearUser()
history.push('/login')
localStorage.removeItem("prev_url")
verifyLoggedIn(false)

},[])


}


const Footer = () => {
  const [isLandScape, isPortrait] = useMediaQuery([{
    orientation: "landscape"
  }, {
    orientation: "portrait"
  }]);

    return ( 

      isPortrait ?

      <VStack p={3} justifyContent='space-between' alignItems='center' style={ FooterStyle }>
        <Text style={{color:'silver'}}    >Copyright &copy; 3x0  </Text>
        <HStack space={3}  >
            <li>
              <a href="https://3x0.pythonanywhere.com/" style={{color:'silver'}} >About Us</a>
            </li>
            <li>
              <a href="/intl/en/policies/privacy/" style={{color:'silver'}}>Privacy</a>
            </li>
            <li>
              <a href="/intl/en/policies/terms/" style={{color:'silver'}}>Terms & Conditions</a>
            </li>
          </HStack>

        <Text  style={{color:'silver'}}  >SummarizeAI@2023 </Text>

</VStack>  :  <HStack p={3} justifyContent='space-between' alignItems='center' style={ FooterStyle }>
        <Text    style={{color:'silver'}} >Copyright &copy; 3x0  </Text>
        <HStack space={3}  >
            <li>
              <a href="https://3x0.pythonanywhere.com/" style={{color:'silver'}} >About Us</a>
            </li>
            <li>
              <a href="/intl/en/policies/privacy/" style={{color:'silver'}}>Privacy</a>
            </li>
            <li>
              <a href="/intl/en/policies/terms/" style={{color:'silver'}}>Terms & Conditions</a>
            </li>
          </HStack>

        <Text   style={{color:'silver'}} >SummarizeAI@2023 </Text>

</HStack> )
}


  // Inline styles
  const containerStyle = {
    margin: '20px',
    fontFamily: 'Arial, sans-serif',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  };

  const labelStyle = {
    fontSize: '1.2em',
    marginBottom: '10px',
    color: '#333',
    fontWeight: 'bold'
  };

  const selectStyle = {
    width: '100%',
    maxWidth: '350px',
    minWidth: '200px',
    padding: '3px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    fontSize: '1em',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    transition: 'border-color 0.3s ease, box-shadow 0.3s ease',
    outline: 'none',
    marginTop: '0px'
  };

  const selectStyleFocused = {
    ...selectStyle,
    borderColor: '#007bff',
    boxShadow: '0 0 5px rgba(0, 123, 255, 0.5)'
  };



const Header = ({loggedIn}) => {

   const [shouldOverlapWithTrigger] = React.useState(false);
   const [shouldOverlapWithTrigger2] = React.useState(false);
  const [position, setPosition] = React.useState("auto");
 const [history, setHistory] = React.useState({});
const historyl = useHistory()


 const [isLandScape, isPortrait] = useMediaQuery([{
    orientation: "landscape"
  }, {
    orientation: "portrait"
  }]);


 React.useEffect(() => {
    const storedHistory = JSON.parse(localStorage.getItem('history')) || {};
    setHistory(storedHistory);
  }, []);


  const handleSelectChange = (event) => {
    const selectedId = event.target.value;
    if (selectedId) {

       localStorage.setItem("historyview",selectedId)
       historyl.push('/build')
    } else {
      // alert("No content found for the selected ID");
    }
  };

    return ( loggedIn ? <> <HStack justifyContent='space-between' bg='white' p={1} px={3} alignItems='center' 
    style={{borderBottom:' 1px solid silver'}} > 
      <HStack space={4} alignItems='center' > 
      <HStack space={1} mr={6} > 
      <img src={LogoColor} style={{height:'30px',marginTop:3}} />
      <p className=" fascinate my-0 " style={{fontSize:'1.3rem'}}  >SummarizeAI</p>

      </HStack>
 <Menu    w="160" shouldOverlapWithTrigger={shouldOverlapWithTrigger}  
    placement={position == "auto" ? undefined : position} trigger={triggerProps => {
      return   <Link alignSelf="center" {...triggerProps}  
      _text={{color:"coolGray.500",fontSize:'1rem', textDecoration:'none'}} ><HStack mt={1} space={.8} alignItems='center' >
       <span style={{color:'#374151'}} >WorkSpaces</span> <svg width="14px" height="14px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M6 9L12 15L18 9" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg> </HStack>  </Link> 
    }}>
        <Menu.Item py={1} h={'35px'} >
        <HStack  justifyContent='space-between' alignItems='center' > 
        <Text my={0} > {String(getUser().Username).toUpperCase()} </Text> <span className=' px-1 bg-white rounded text-danger font-10 ' > Starter </span> </HStack> </Menu.Item>
        <Divider />
        <Menu.Item><Link href="/new_team" _text={{textDecoration:'none'}} > Create New Team </Link></Menu.Item>
      </Menu>


<Link href='/explore' _text={{color:"coolGray.700",fontSize:'1rem', textDecoration:'none'}} > Explore </Link>


 <Button h="31px" ml='4' bg='blue.600'   leftIcon={<svg width="20px" height="20px"   viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 20V19C4 16.2386 6.23858 14 9 14H12.75M17.5355 13.9645V17.5M17.5355 17.5V21.0355M17.5355 17.5H21.0711M17.5355 17.5H14M15 7C15 9.20914 13.2091 11 11 11C8.79086 11 7 9.20914 7 7C7 4.79086 8.79086 3 11 3C13.2091 3 15 4.79086 15 7Z" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>}>
        Invite
      </Button>



      </HStack>
        

         

        <HStack space={3} >

    <Input h={'37px'} color="coolGray.900" overflow="none"  borderColor='silver' bg='coolGray.100'   fontSize='14px'     borderRadius="30px"  placeholder="Search SummarizeAI"     InputLeftElement={
        <svg height="24px" width="24px" className="mx-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g clip-path="url(#clip0_15_152)"> <rect width="24" height="24" fill="none"></rect> <circle cx="10.5" cy="10.5" r="6.5" stroke="#bdbdbd" stroke-linejoin="round"></circle> <path d="M19.6464 20.3536C19.8417 20.5488 20.1583 20.5488 20.3536 20.3536C20.5488 20.1583 20.5488 19.8417 20.3536 19.6464L19.6464 20.3536ZM20.3536 19.6464L15.3536 14.6464L14.6464 15.3536L19.6464 20.3536L20.3536 19.6464Z" fill="#bdbdbd"></path> </g> <defs> <clipPath id="clip0_15_152"> <rect width="24" height="24" fill="white"></rect> </clipPath> </defs> </g></svg> }
        />
     


 <Menu  mx={2}  w="230" shouldOverlapWithTrigger={shouldOverlapWithTrigger2}  
    placement={position == "auto" ? undefined : position} trigger={triggerProps => {
      return    <Pressable   {...triggerProps} > <div className="circle py-0 my-0"  >
      {String(getUser().Username).toUpperCase().slice(0,2)} 
    </div>
    </Pressable>
    }}>
    
        <Menu.Item  ><Link href="/profile" _text={{textDecoration:'none'}} > {String(getUser().Email)}  </Link></Menu.Item>
        <Divider />
        <Menu.Item><Link href="/settings" _text={{textDecoration:'none'}} > User Settings </Link></Menu.Item>
        <Menu.Item><Link href="/logout" _text={{textDecoration:'none','color':'red.700',fontWeight:'bold'}} > Sign Out</Link></Menu.Item>
      </Menu>



        </HStack>
        </HStack>
  </>
           : 
        

isPortrait ?


<VStack space={6} justifyContent='space-between' p={2} px={3} alignItems='center' > 
      <HStack space={1} >
      <img src={Logo} style={{height:'40px',marginTop:3}} />
    <Link href="/" _text={{textDecoration:'none'}} >
     <Text fontWeight='bold' mt={-2} mx={1}  className=" fascinate my-0 " style={{ fontSize:'2.4rem'}}  >SummarizeAI  </Text>
 </Link>
      </HStack>
<Divider />

<HStack space='6' alignItems="center" > 
      <select
        id="content-select"
        style={selectStyle}
        onChange={handleSelectChange}
        defaultValue=""
      >
        <option value="" >History</option>
        {Object.keys(history).map(id => (
          <option key={id} value={id}>{id}</option>
        ))}
      </select>

        <ToggleDarkMode /> 
        </HStack>
        </VStack>



:
 <HStack justifyContent='space-between' p={2} px={3} alignItems='center' > 
      <HStack space={1} >
      <img src={Logo} style={{height:'40px',marginTop:3}} />
      <Link href="/" _text={{textDecoration:'none'}} >
        <Text fontWeight='bold' mt={-2} mx={1}  className=" fascinate my-0 " style={{ fontSize:'2.4rem'}}  >SummarizeAI  </Text>
 </Link>
 
      </HStack>

<a href="https://www.producthunt.com/posts/summarizeai?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-summarizeai" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=491965&theme=light" alt="SummarizeAI - Summarize&#0032;any&#0032;text&#0032;in&#0032;minutes&#0032;and&#0032;personalize&#0032;your&#0032;results | Product Hunt" 
style={{"width": "250px", height: "54px",}} width="250" height="54" /></a>
<HStack space='6' alignItems="center" > 


      <select
        id="content-select"
        style={selectStyle}
        onChange={handleSelectChange}
        defaultValue=""
      >
        <option value="" >History</option>
        {Object.keys(history).map(id => (
          <option key={id} value={id}>{id}</option>
        ))}
      </select>

        <ToggleDarkMode /> 
        </HStack>
        </HStack>
    )
}

const FooterStyle = {
    background: "#222",
    fontSize: ".8rem",
    color: "#fff",
    width: "100%",
    opacity: ".9"
}

const HeaderStyle = {
 
    fontSize: ".8rem",
    color: "#fff",
    position: "absolute",
    top: 0,
    padding: ".6rem",
    margin: 0,
    width: "100%",
    opacity: ".5"
}


function App() {
  const [loggedIn,setLoggedIn] = React.useState()
  const [sideMenu,setSideMenu] = React.useState('Collections')


  const { colorMode } = useColorMode();
  const Home = LoginRequired(SavedPageHistory(HomePage))
  const {store,persistor} = useStore({})




React.useEffect(()=>{
localStorage.removeItem('historyview') 

},[]) 


  return (
       <Provider store={store} >  
 <PersistGate loading={null} persistor={persistor}>
    <Router >

<Box 
      bg={ loggedIn ? "coolGray.100" :colorMode === "light" ? "coolGray.50" : "coolGray.900"}
    >
    <Header loggedIn={loggedIn} />
  <Helmet> 
                <meta name="description" content="Discover the best AI summarization tools for quickly and efficiently summarizing long text. Enhance your productivity with our automated text summarization software." />
                <meta name="keywords" content="AI text summarization, automated summarization, document summarizer, AI-powered summarization, business report summarization, meeting notes summarization, natural language processing" />
                <meta property="og:title" content="AI Summarizer: Effortlessly Summarize Long Text with Our Tool" />
                <meta property="og:description" content="Discover the best AI summarization tools for quickly and efficiently summarizing long text. Enhance your productivity with our automated text summarization software." />
                <meta property="og:image" content="https://res.cloudinary.com/djpcokxvn/image/upload/v1727230433/2024-09-25_03_12_49-SummarizeAI_and_10_more_pages_-_Personal_-_Microsoft_Edge_i6ylfp.png" />
                <meta property="og:url" content="https://summarizeaibot.netlify.app/" />

      </Helmet>
     <Switch>
                    <Route exact path="/" component={ LandingPage } />
                    <Route path="/login" component={ LoginPage } />
                    <Route path="/build" component={ BuildPage } />
                    <Route path="/register" component={ RegisterPage } />
                    <Route path="/forget-password" component={  ForgetPasswordPage } />
                    <Route path="/collections" render={(props) => <Home {...props} verifyLoggedIn={e=>setLoggedIn(e) } sideMenu={sideMenu} setSideMenu={setSideMenu} />}   />
                    <Route path="/logout" render={(props) => <LogOut {...props} verifyLoggedIn={e=>setLoggedIn(e) } />}   />
                
                <Route render={() => <Redirect to="/build" />} />
                </Switch>

                           { loggedIn ? <></>: <Footer />}
      
 
    </Box>

    </Router>
    </PersistGate>
    </Provider>
    
  );
}

function ToggleDarkMode() {
  const { colorMode, toggleColorMode } = useColorMode('light');
  return (
    <HStack space={2}>
      <Text>Dark</Text>
      <ToggleSwitch 
        isChecked={colorMode === "light"}   onToggle={toggleColorMode}
        accessibilityLabel={
          colorMode === "light" ? "switch to dark mode" : "switch to light mode"
        }
      />
      <Text>Light</Text>
    </HStack>
  );
}

export default App;
