import React, { useRef } from 'react';
import htmlParser from 'html-react-parser';
import html2pdf from 'html2pdf.js';
import {VStack,HStack} from 'native-base'


const HtmlViewer = ({ htmlContent="",state={} }) => {
  const contentRef = useRef(null);

  // Function to export content to PDF
  const handleExportToPDF = async () => {
    const element = contentRef.current; // Get the current HTML content

    // Use html2pdf.js to create the PDF
    const opt = {
      margin: [0.5, 0.5, 0.5, 0.5], // Margins: top, right, bottom, left
      filename: 'summary_content.pdf',
      html2canvas: {
        scale: 2,  // Higher scale for better quality
        useCORS: true  // Enable Cross-Origin Resource Sharing
      },
      jsPDF: {
        orientation: 'portrait',
        unit: 'in',
        format: 'letter'  // Paper format
      }
    };

    html2pdf().from(element).set(opt).save();
  };
 


const saveContent = () => {
  const id = htmlContent.replace(/<\/?[^>]+(>|$)/g, "").slice(0, 40);
  const history = JSON.parse(localStorage.getItem('history')) || {};
  history[id] = {...state, content:htmlContent};
  localStorage.setItem('history', JSON.stringify(history));
  alert("Content saved successfully");
};


const handleCopy = () => {
    const range = document.createRange();
    range.selectNodeContents(contentRef.current);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand('copy');
    selection.removeAllRanges();
    alert("Content copied");
  };


 


  return (

    <VStack space={3} >
    <HStack space={4} justifyContent="flex-end" >
      <button onClick={saveContent} style={saveButtonStyle}>Save</button>
      <button onClick={handleExportToPDF} style={exportButtonStyle}>Export to PDF</button>
      </HStack>
      <button
        style={{
          position: 'absolute',
          top: '90px',
          right: '5px',
          fontSize: '12px',
          padding: '5px',
          cursor: 'pointer',
          zIndex: 1,
           background: 'gray',
    border: '0px solid',
    color: 'white',
    width: '64px'
        }}
        onClick={handleCopy}
      >
        Copy
      </button>

       <div  contentEditable="true" ref={contentRef} style={{ padding: '20px', border: '1px solid #ddd',  background: 'white',
      }}>
        {htmlParser(htmlContent)}
      </div>
    
    </VStack>
  );
};

// Optional: Add some styling for the export button
const exportButtonStyle = {
  marginTop: '20px',
  padding: '10px 20px',
  backgroundColor: '#4CAF50',
  color: 'white',
  border: 'none',
  cursor: 'pointer',
  borderRadius: '5px'
};

// Optional: Add some styling for the export button
const saveButtonStyle = {
  marginTop: '20px',
  padding: '10px 20px',
  backgroundColor: '#1e53a2',
  color: 'white',
  border: 'none',
  cursor: 'pointer',
  borderRadius: '5px',
  'width': '140px'
};

const contentStyle = {
  padding: '20px',
  border: '1px solid #ddd',
  maxHeight: '50vh',  // Adjust the maximum height as needed
  // Hide horizontal scrolling (optional)
};


export default HtmlViewer;
