import React from 'react'
import { Link } from 'react-router-dom'
import {
  Text,
  HStack,
  Pressable,
  VStack, Center,Tooltip,Input,Menu,Checkbox
} from "native-base";

import {getUser} from '../helper/authentication'
import "../assets/styles/collection_view.css"
import "../Circle.css"
import "../Table.css"


const Variables = ({collection}) => {

const [variables,setVariables] = React.useState([
{'variable':'baseUrl','initialValue':'','currentValue':'https://postman-echo.com','id':generateUniqueId()},
])
   const [shouldOverlapWithTrigger2] = React.useState(false);
  const [position, setPosition] = React.useState("auto");
  const [search, setSearch] = React.useState("");


function generateUniqueId() {
  return Math.random().toString(36).substr(2, 16); // Generates a random 9-character alphanumeric string
}


const addVariable = (key)=>{
const new_variables = [...variables]
  const newItem = {
    variable: '',
    initialValue: "",
    currentValue: "",
    id: generateUniqueId(),
  };

  const new_v = [
    ...new_variables.slice(0, key),
    newItem,
    ...new_variables.slice(key)
  ];

setVariables(new_v)

}


const removeVariable = (key)=>{
const new_variables = [...variables.filter(e=>String(e.id) !== String(key) )]
setVariables(new_variables)

}

const filterTable = (data,search)=>{
	if (search){
		return data.filter(e=>String(e.variable).toLowerCase().indexOf(search.toLowerCase()) >= 0 || 
		String(e.initialValue).toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
		String(e.currentValue).toLowerCase().indexOf(search.toLowerCase()) >= 0   )
	} else {
	return variables
} 

}

const updateVariable = (val,key,label)=>{
let new_variables = [...variables]
new_variables.forEach(e=>{
if (String(e.id) === String(key)){
	e[label] = val
}

})
 
setVariables(new_variables)


} 

  return <VStack space={4} mt={4} > 
 

    <Input placeholder="Search Variables"  color="coolGray.900"  w='280px' h={'37px'} 
     overflow="none"  borderColor='silver'    
    fontSize='13px' onChangeText={val=>setSearch(val)}      
    InputLeftElement={
        <svg height="34px" width="34px" className="mx-2"  viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M11.132 9.71395C10.139 11.2496 10.3328 13.2665 11.6 14.585C12.8468 15.885 14.8527 16.0883 16.335 15.065C16.6466 14.8505 16.9244 14.5906 17.159 14.294C17.3897 14.0023 17.5773 13.679 17.716 13.334C18.0006 12.6253 18.0742 11.8495 17.928 11.1C17.7841 10.3573 17.4268 9.67277 16.9 9.12995C16.3811 8.59347 15.7128 8.22552 14.982 8.07395C14.2541 7.92522 13.4982 8.00197 12.815 8.29395C12.1254 8.58951 11.5394 9.08388 11.132 9.71395Z" stroke="#5c5c5c" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M17.5986 13.6868C17.2639 13.4428 16.7947 13.5165 16.5508 13.8513C16.3069 14.1861 16.3806 14.6552 16.7154 14.8991L17.5986 13.6868ZM19.0584 16.6061C19.3931 16.85 19.8623 16.7764 20.1062 16.4416C20.3501 16.1068 20.2764 15.6377 19.9416 15.3938L19.0584 16.6061ZM7.5 12.7499C7.91421 12.7499 8.25 12.4142 8.25 11.9999C8.25 11.5857 7.91421 11.2499 7.5 11.2499V12.7499ZM5.5 11.2499C5.08579 11.2499 4.75 11.5857 4.75 11.9999C4.75 12.4142 5.08579 12.7499 5.5 12.7499V11.2499ZM7.5 15.7499C7.91421 15.7499 8.25 15.4142 8.25 14.9999C8.25 14.5857 7.91421 14.2499 7.5 14.2499V15.7499ZM5.5 14.2499C5.08579 14.2499 4.75 14.5857 4.75 14.9999C4.75 15.4142 5.08579 15.7499 5.5 15.7499V14.2499ZM8.5 9.74994C8.91421 9.74994 9.25 9.41415 9.25 8.99994C9.25 8.58573 8.91421 8.24994 8.5 8.24994V9.74994ZM5.5 8.24994C5.08579 8.24994 4.75 8.58573 4.75 8.99994C4.75 9.41415 5.08579 9.74994 5.5 9.74994V8.24994ZM16.7154 14.8991L19.0584 16.6061L19.9416 15.3938L17.5986 13.6868L16.7154 14.8991ZM7.5 11.2499H5.5V12.7499H7.5V11.2499ZM7.5 14.2499H5.5V15.7499H7.5V14.2499ZM8.5 8.24994H5.5V9.74994H8.5V8.24994Z" fill="#5c5c5c"></path> </g></svg> }
        />
        

      <table class="table table-bordered table-sm">
  <thead>
    <tr>
      <th scope="" className='col-1' ></th>
      <th scope="col" class='px-3' >Variable</th>
      <th scope="col" class='px-3'>Initial Value</th>
      <th scope="col" class='px-3'>Current Value</th>
      <th scope="col" className='col-2 px-4' ><Menu  mx={3} w='120px'   bg='white'  shouldOverlapWithTrigger={shouldOverlapWithTrigger2}  
    placement={'left'} trigger={triggerProps => {
      return    <Pressable   {...triggerProps} >  
      <svg width="22px" height="22px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M7 12C7 13.1046 6.10457 14 5 14C3.89543 14 3 13.1046 3 12C3 10.8954 3.89543 10 5 10C6.10457 10 7 10.8954 7 12Z" fill="#1C274C"></path> <path d="M21 12C21 13.1046 20.1046 14 19 14C17.8954 14 17 13.1046 17 12C17 10.8954 17.8954 10 19 10C20.1046 10 21 10.8954 21 12Z" fill="#1C274C"></path> <path opacity="0.5" d="M14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z" fill="#1C274C"></path> </g></svg>
    </Pressable>
    }}>
        <Menu.Item  ><Pressable _hover={{color:'white'}}  _text={{textDecoration:'none',}} style={{fontSize:'12px'}} > Persist All </Pressable></Menu.Item> 
        <Menu.Item><Pressable  _hover={{color:'white'}}   _text={{textDecoration:'none'}}  style={{fontSize:'12px'}}> Reset All </Pressable></Menu.Item>
      </Menu></th>
    </tr>
  </thead>
  <tbody>
  {filterTable(variables,search).map((vars,key)=>(
    <tr key={key} >
      <th scope="row">
      <HStack space={2} ml={1} mr={3} alignItems='center' justifyContent='center' > 
 
<Pressable onPress={e=>addVariable(key+1)} > <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M7 12L12 12M12 12L17 12M12 12V7M12 12L12 17" stroke="#2f3735" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg></Pressable>
     <Checkbox defaultIsChecked size="sm" colorScheme='teal'  />

      </HStack></th>
      <td><Input value={vars.variable} onChangeText={val=>updateVariable(val,vars.id,'variable')}  color="coolGray.900"   h={'27px'} overflow='none' borderColor='white'  /></td>
     <td><Input value={vars.initialValue || vars.currentValue} onChangeText={val=>updateVariable(val,vars.id,'initialValue')}     color="coolGray.900"   h={'27px'} overflow='none' borderColor='white'  /></td>
    <td><Input value={vars.currentValue}  onChangeText={val=>updateVariable(val,vars.id,'currentValue')}  color="coolGray.900"   h={'27px'} overflow='none' borderColor='white'  /></td>
      <td className='  px-4'> {variables.length > 1 ? <Pressable onPress={e=>removeVariable(vars.id)}> <svg width="15px" height="15px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path fill="#ff0000" d="M160 256H96a32 32 0 0 1 0-64h256V95.936a32 32 0 0 1 32-32h256a32 32 0 0 1 32 32V192h256a32 32 0 1 1 0 64h-64v672a32 32 0 0 1-32 32H192a32 32 0 0 1-32-32V256zm448-64v-64H416v64h192zM224 896h576V256H224v640zm192-128a32 32 0 0 1-32-32V416a32 32 0 0 1 64 0v320a32 32 0 0 1-32 32zm192 0a32 32 0 0 1-32-32V416a32 32 0 0 1 64 0v320a32 32 0 0 1-32 32z"></path></g></svg></Pressable>:<></>} </td>
    </tr>

  	))}

   
  </tbody>
</table>



</VStack> ;

}


export default Variables


