import React from 'react'
import { Link } from 'react-router-dom'
import {
  Text,
  HStack,
  Pressable,
  VStack,Divider,Input,Badge,Box,Center,useColorModeValue,Menu,Heading,Button,Flex
} from "native-base";

import {getUser} from '../helper/authentication'
import { TabView, SceneMap ,TabBar} from 'react-native-tab-view';
import "../assets/styles/collection_view.css"
import "../Circle.css"
import PApps from './web_apps'
import Variables from './variables'
import BodyContext from './BodyContext'
import EditablePre from './editable_pre'

const description = `Postman provides dynamic variables that use the Faker library to generate sample data, including random names, addresses, and email addresses.

This collection demonstrates the use of dynamic variables to create randomized mock data. The examples in this collection utilize various data types and showcase how dynamic variables can help simulate real-world data within your API testing environment.

<b>Using this collection</b>

Step 1: Send requests
Select a request from the collection, review the request parameters and pre-request scripts, 
then hit "Send" to execute the request.

Step 2: Review the "Body" and "Pre-request Script" tabs
Understand how dynamic variables are used to generate mock data. Dynamic variables can be used in the request URL and Body, or even as part of a Pre-Request or Test script for more complex use cases (see "Create mock blog post" request).

Step 3: Customize (optional)
Customize dynamic variables to better suit your specific use case. Save the changes, and execute the requests.

Step 4: Analyze responses
Examine the response body to see the generated mock data. Note that the responses will be different each time you send the request, as the dynamic variables ensure randomized data generation.

Enjoy testing with diverse and dynamic data!`


const Prompt = ({collection})=>{


const [details,setDetails] = React.useState({}) 
const [openCol,setOpenCol] = React.useState()
   const [shouldOverlapWithTrigger2] = React.useState(false);
  const [position, setPosition] = React.useState("auto");

  const [isEditing, setIsEditing] = React.useState(false);
  const [content, setContent] = React.useState(description);
const [papps, setPapps] = React.useState([
  {  name:'Papps 1',id:'26'},
   { name:'Papps 2',id:'25'},
    {name:'Papps 3',id:'24'},
    {name:'Papps 4',id:'23'},
    {name:'Papps 5',id:'22'},
   { name:'Papps 6',id:'21'},
]);


const getDetails = ()=> collection


React.useState(()=>{

setDetails(getDetails())

},[collection.id ])




const Overview = () => <HStack justifyContent='space-between' >

<VStack m={6} space={6} w='70%' h='80vh' pb={7} >

<HStack space={2} alignItems='center' justifyContent='space-between' >
<Heading color='coolGray.800' fontSize='1.6em' > About this collection </Heading>
<Pressable  >
{!isEditing ?
<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9.65661 17L6.99975 17L6.99975 14M6.10235 14.8974L17.4107 3.58902C18.1918 2.80797 19.4581 2.80797 20.2392 3.58902C21.0202 4.37007 21.0202 5.6364 20.2392 6.41745L8.764 17.8926C8.22794 18.4287 7.95992 18.6967 7.6632 18.9271C7.39965 19.1318 7.11947 19.3142 6.8256 19.4723C6.49475 19.6503 6.14115 19.7868 5.43395 20.0599L3 20.9998L3.78312 18.6501C4.05039 17.8483 4.18403 17.4473 4.3699 17.0729C4.53497 16.7404 4.73054 16.424 4.95409 16.1276C5.20582 15.7939 5.50466 15.4951 6.10235 14.8974Z" stroke="#6bb7bd" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
:
 <svg  width="16px" height="16px"  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M18.1716 1C18.702 1 19.2107 1.21071 19.5858 1.58579L22.4142 4.41421C22.7893 4.78929 23 5.29799 23 5.82843V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H18.1716ZM4 3C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21L5 21L5 15C5 13.3431 6.34315 12 8 12L16 12C17.6569 12 19 13.3431 19 15V21H20C20.5523 21 21 20.5523 21 20V6.82843C21 6.29799 20.7893 5.78929 20.4142 5.41421L18.5858 3.58579C18.2107 3.21071 17.702 3 17.1716 3H17V5C17 6.65685 15.6569 8 14 8H10C8.34315 8 7 6.65685 7 5V3H4ZM17 21V15C17 14.4477 16.5523 14 16 14L8 14C7.44772 14 7 14.4477 7 15L7 21L17 21ZM9 3H15V5C15 5.55228 14.5523 6 14 6H10C9.44772 6 9 5.55228 9 5V3Z" fill="#0F0F0F"></path> </g></svg>
}
</Pressable>
</HStack>

<EditablePre content={content} setContent={e=>{
  setContent(e)
  } } edit={isEditing} />



</VStack>

 <VStack  h='80vh' w='30%' justifyContent='center'   mt={-10} pl={10} > 
 <VStack space={2} >
<Text color="coolGray.600" fontWeight='bold' fontSize='11px'  >Created by </Text>
<HStack space={2} alignItems='center' > 
<div className='circle-0 bg-danger py-1 px-2'>
<svg height="44px" width="44px" version="1.1" id="Layer_1" viewBox="0 0 354.856 354.856"  fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path fill="#333E48" d="M307.943,199.173c16.571-15.084,26.979-36.832,26.979-61.008c0-45.55-36.925-82.474-82.474-82.474 c-33.914,0-63.045,20.476-75.713,49.737c15.541,17.534,24.992,40.582,24.992,65.8c0,21.149-6.804,41.654-19.089,58.524 c11.606,10.24,21.178,22.617,28.169,36.35h144.049C348.867,237.946,331.568,213.976,307.943,199.173z"></path> <path fill="#61B4E4" d="M0,299.166h204.811c-5.986-28.155-23.285-52.126-46.912-66.929 c16.573-15.084,26.979-36.832,26.979-61.009c0-45.549-36.924-82.474-82.474-82.474c-45.545,0-82.471,36.925-82.471,82.474 c0,24.177,10.404,45.925,26.978,61.009C23.284,247.04,5.986,271.01,0,299.166z"></path> </g> </g></svg>
</div>
<Text color='coolGray.600' > You </Text>
</HStack>
</VStack>


 <VStack space={2} mt={4} >


<Text color="coolGray.600" fontWeight='bold' fontSize='11px'  >Created on </Text>
<Text color='coolGray.600' > {details.created} </Text>

 </VStack>



 </VStack>

 </HStack>;








const Papps = () => <VStack space={3} py={4} p={4}  h='80vh' overflowX='hidden'  >

<Text   color='coolGray.600' fontWeight='bold' >Start a new Prompt App</Text>

<PApps papps={papps} />

</VStack> ;






const VariablesTabs = () => <VStack m="4">
    <Text color="coolGray.600" > These variables are specific to this collection and its prompt apps. Learn more about <Link>collection variables.</Link>  </Text>

    <Variables collection={collection} />
  </VStack>;





const Body = () =>  <VStack m="4">
    <Text color="coolGray.600" > ! What would you like these collection to know about to provide better responses, these are specific to this collection and its prompt apps. Learn more about <Link>Body Context.</Link>  </Text>
    <BodyContext collection={collection} />

  </VStack> 

const initialLayout = {
 
};
const renderScene = SceneMap({
  first: Overview,
  second: Papps,
  third: VariablesTabs,
  fourth: Body
});

function CollectionTabs({description}) {

  const [index, setIndex] = React.useState(0);
  const [routes] = React.useState([{
    key: 'first',
    title: 'Overview'
  }, {
    key: 'second',
    title: 'Prompt Apps'
  }, {
    key: 'third',
    title: 'Variables'
  }, {
    key: 'fourth',
    title: 'Body Context'
  }]);


 const renderTabBar = props => ( 
  <TabBar  
    {...props}

      renderLabel={({ route, focused, color }) => (
    <Text   style={{ color:'gray', margin: 1}}>
     <HStack my={0} py={0} alignItems='center' justifyContent='center' space={1} > {route.title} {route.title === 'Variables' ?<div class='circle-small bg-success p-0 m-0' ></div>  :<></>}</HStack>
    </Text>
)}

    indicatorStyle={{ backgroundColor: 'red',marginRight:'0px'}}
    style={{ backgroundColor: 'white', width:'70%' }}
  />
);

    return <VStack   > <TabView navigationState={{
    index,
    routes
  }}  renderTabBar={renderTabBar} renderScene={renderScene}   onIndexChange={setIndex} 
 
   /> </VStack> ;
}


const getDirIcon = (col)=>{
return <Badge colorScheme="info" bg='none'  _text={{fontSize:'11px',color:'yellow.400',fontWeight:'bol'}} py={0} w='50px' >{String(col.llm).slice(0,6) }</Badge>

}







	return (<VStack w='100%' p={1}    >


 <CollectionTabs />

 


</VStack>)
}


export default Prompt

