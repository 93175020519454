import React from 'react';
import ReactDOM from 'react-dom';
import { Link,useHistory,withRouter,useLocation    } from 'react-router-dom'


const mock_user_data =  {
Username:'tunde@adroit',MenuId:null,Language:'en',  Language:'en',
"Operator":"adroit","Operation":"201-en","Workstation":"xxxxxx0-1836688",
}


export function setUser(user={}) {
const menu_id = localStorage.setItem('profile',JSON.stringify(user))

}


export function clearUser() {
  localStorage.removeItem('profile')
}




export function getUser() {
let user =  localStorage.getItem('profile')
user = user ? JSON.parse(user):{}

 
  const save_token = `-menu-${user.UserId}`

let menu_data = localStorage.getItem(save_token)

menu_data = menu_data ? JSON.parse(menu_data):{}

return { ...user,MenuId:menu_data.MenuId}

}

 



export const LoginRequired = (WrappedComponent) => {

  class LoginRequired extends React.Component {

    componentDidMount() {
      if (!getUser().isLoggedIn){
        const push_url =  '/login'
        this.props.history.push(push_url);
      } else {
        this.props.verifyLoggedIn(true)
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props} 
        />
      );
    }
  }

  LoginRequired.displayName = `loginRequired(${WrappedComponent.displayName || WrappedComponent.name})`;

  return withRouter(LoginRequired);
};





export const withLoading = (WrappedComponent) => {
  class WithLoading extends React.Component {
    state = {
      isLoading: true,
    };

    componentDidMount() {
      setTimeout(() => {
        this.setState({ isLoading: false });
      }, 2000);
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          loading={this.state.isLoading}
        />
      );
    }
  }

  WithLoading.displayName = `withLoading(${WrappedComponent.displayName || WrappedComponent.name})`;

  return WithLoading;
};




export const SavedPageHistory = (WrappedComponent) => {
  class SavedPageHistory extends React.Component {

    componentDidMount() {
    localStorage.setItem("prev_url",window.location.pathname)
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
        />
      );
    }
  }

  SavedPageHistory.displayName = `SavedPageHistory(${WrappedComponent.displayName || WrappedComponent.name})`;

  return withRouter(SavedPageHistory);
};
