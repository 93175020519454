import React,{useRef} from 'react'
import { Link } from 'react-router-dom'
import {VStack,HStack,Divider,Text,useMediaQuery} from 'native-base'
import '../../App.css'
import {Banner300by250,Banner728by90} from "../Banner";


export default function LandingPage() {

   const [isLandScape, isPortrait] = useMediaQuery([{
    orientation: "landscape"
  }, {
    orientation: "portrait"
  }]);

    return (
        <HStack  space={3} justifyContent='space-between' style={ isPortrait ?MobileHeaderStyle: HeaderStyle }  direction={{sm:"column",md:"row"}}   >
        

        <HStack  w={{sm:'100%',md:'45%'}} >
        <div>
        <VStack space={7} mb={-12} >
             <Text textAlign={isPortrait ?'center':'left'} mt= {isPortrait ? "4":"0"} fontSize= {isPortrait ? "4xl":"6xl"} fontWeight='bold'  className="main-title  " >Create Engaging Content in Minutes ...  </Text>

            <Text fontSize="19px" className="main-para">With SummarizeAI, you can effortlessly generate concise and captivating content from any text, whether for ebooks, articles, or reports. Our platform empowers professionals, authors, and businesses to quickly distill key insights and craft engaging summaries on their chosen subjects or ideas in just minute.</Text>
            
</VStack>

            <div className="buttons">
                <Link to="/build">
                    <button className="primary-button button-63" style={{
                        textDecoration:'none',minWidth:isPortrait ?"100%": "15rem"
                    }} ><span>Start Summarizing <span>&#187;</span></span> </button>
                </Link>
    
            </div>

<Divider my={12} />

<div class="maia-cols " id="as-home-pslots">
          <div class={isPortrait ? "maia-col-12":"maia-col-4"} id="as-home-pslots-1"   >
            <Text  className='header-ps' fontWeight="bold" mb={4} style={{fontSize:"18px"}} >
              Efficiency and Simplicity
            </Text>
            <p/>
            <Text style={{ marginBottom:'10px',marginTop:'10px',lineHeight:"1.4",height:"120px"}} >
              We streamlines the process of summarizing, making it easy for anyone to create high-quality content fast.
            </Text>
         
          </div>


          <div class={isPortrait ? "maia-col-12":"maia-col-4"} id="as-home-pslots-2">
              <Text  className='header-ps' fontWeight="bold" mb={4} style={{fontSize:"18px"}} >
              AI-Powered Intelligence
            </Text>
             <p/>
            <Text style={{ marginTop:'10px',marginBottom:'10px',lineHeight:"1.4",height:"120px"}} >
             We combine artificial intelligence with your creativity to bring your ideas to life. Let AI guide your content creation process.
            </Text>
           
          </div>
          <div class={isPortrait ? "maia-col-12":"maia-col-4"} id="as-home-pslots-3">
            <Text  className='header-ps' fontWeight="bold" mb={4} style={{fontSize:"18px"}} >
              Customization
            </Text>
             <p/>
            <Text style={{ marginTop:'10px',marginBottom:'10px',lineHeight:"1.4",height:"120px"}} >
              Refine your summaries to fit your unique needs with our intuitive customization tools.
            </Text>
          
          </div>
        </div>


</div>
            </HStack>

              <HStack   mt={'4rem'} >

<VStack space={6} w={'100%'} >
            
      <iframe width={isPortrait ? "100%":"696"} height="392" src="https://www.youtube.com/embed/nUX0xIZ73bE" title="Prompt Engineering Tutorial – Master ChatGPT and LLM Responses" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              <Text href="https://youtu.be/nUX0xIZ73bE" style={{ 
                fontSize:'1.2rem'
            }} >Watch a tutorial and overview of SummarizeAI.</Text> 

              <Banner728by90  />  

          </VStack>


              </HStack>
        </HStack>
    )
}

const HeaderStyle = {
    minHeight: "78.5vh",
    marginLeft:'3rem',
    marginTop:'3rem',
    marginBottom:'3rem',
}

const MobileHeaderStyle = { 
    margin :'.6rem', 
}