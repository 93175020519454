
import defaultState from './dataStore'
import moment from 'moment' 


 const Collections = (state = defaultState.collections, { type, payload }) => {
  
  switch (type) {

    case "SET_COLLECTION":{
// console.log('state results ==',payload)
     return {...state,data:[...state.data,payload]}

    }    

//     case "SET_COLLECTION_TYPE":{
//       return {...state,type:payload.type}

//     }

//         case "SET_COLLECTION_LOAD":{

// return {...state,loading:!state.loading}

//     }

//        case "SET_HIT_VALUE":{

// return {...state,hitValue:payload.hitValue}

//     }

    default:{
        return state
        
    }

  }
}
 

 export const Prompts = (state = defaultState.prompts, { type, payload }) => {
  
  switch (type) {

    case "SET_PROMPT":{
// console.log('state results ==',payload)
     return {...state,data:payload.results}

    }    

//     case "SET_COLLECTION_TYPE":{
//       return {...state,type:payload.type}

//     }

//         case "SET_COLLECTION_LOAD":{

// return {...state,loading:!state.loading}

//     }

//        case "SET_HIT_VALUE":{

// return {...state,hitValue:payload.hitValue}

//     }

    default:{
        return state
        
    }

  }
}
 


//   export const searchHistory = (state = defaultState.search_history, { type, payload }) => {
  
//   switch (type) {

//      case "SET_SEARCH_HISTORY":{

//       const {search,options,match,hitValue, results,query,RICARECORDDATE,RICARECORDTIME,RICARECORDCOUNTER,...rest} = payload

//       return {...state,
//         array:[...state.array,
//         {
//           conditions:search,
//           option_conditions:options,
//           query_op:match,

//           ricaResults:results,
//           ricaQuery:query,
//           hitValue:hitValue,
//           ricaRecordId:state.array.length+1, 
//           RICARECORDCOUNTER:state.array.length+1,
//           ...rest}
//         ]}

//     }


//   case "SET_VIEW_SEARCH_HISTORY":{

//       return {...state,active:!state.active}

//     }

     
//     default:{
//         return state
        
//     }

//   }
// }



//  export const Account = (state = defaultState.accounts, { type, payload }) => {
  
//   switch (type) {

//     case "SET_ACCOUNT":{
// // console.log('state results ==',payload)
//       return {...state,array:payload.results,type:payload.type}

//     }

//         case "SET_ACCOUNT_LOAD":{

// return {...state,loading:!state.loading}

//     }

//     default:{
//         return state
        
//     }

//   }
// }
 


//  export const Edit = (state = defaultState.edit, { type, payload }) => {
  
//   switch (type) {
//         case "SET_EDIT":{
//         return {
//           ...state,status:payload.close ? false :!state.status,
//           user:payload.user
//         }
//     }        
//     case "SET_START_EDIT":{
//         return {
//           ...state,start_edit:!state.start_edit,...payload
//         }
//     }
//     default:{
//         return state        
//     }

//   }
// }
 


//  export const Map = (state = defaultState.map, { type, payload }) => {
  
//   switch (type) {
//         case "SET_MAP":{
//         return {
//           ...state,open:payload.open,
//           search: payload.search ? payload.search:''
//         }
//     }    
//     default:{
//         return state        
//     }

//   }
// }
 




//  export const Docs = (state = defaultState.docs, { type, payload,active }) => {
  
//   switch (type) {

//     case "SET_DOCS":{
//       return {...state,data:payload,active:active ? active:!state.active}
//     }

//     case "SET_DOCS_ISSUE":{
//       return {...state,data:
//         {...state.data,customerStatus:payload}
//         }
//     }

//        case "SET_DOCS_AMS":{
//       return {...state,data:
//         {...state.data,ams:payload}
//         }
//     }
    

//         case "SET_DOC_LOAD":{

// return {...state,loading:!state.loading}

//     }
//         case "SET_FORM_SUBMIT":{

//           const {formData,nameMapped,optionType} = payload;
//           console.log('new content',payload,state.data[nameMapped])


//           switch (optionType){

//             case 'edit':{
//               // using key to locate data.
//               // NOTE: Must be change to data unique id
//               const prevData = state.data[nameMapped]
//               let nextData

//               nextData = prevData.filter((obj_data,idKey)=> idKey !== formData.id)
//               nextData = [...nextData,formData]
            
//                return {...state,
//                       data: {...state.data,
//                       [nameMapped]: nextData
//                     }
//               }            

//             }

//             case 'create':{
//                const prevData = state.data[nameMapped] ||[]

//                if (nameMapped==='permits'){


//               return {...state,
//                 data: {...state.data,
//                 [nameMapped]: [formData]
//               }

//               }

//                }

//               return {...state,
//                 data: {...state.data,
//                 [nameMapped]: [...prevData,formData]
//               }

//               }

//              }

//             default:{
//             return state

//             }
//           }

        

//     }

//     default:{
//         return state
        
//     }

//   }
// }


//  export const DocRow = (state = defaultState.docRow, { type, payload }) => {
//   switch (type) {

//     case "SET_ROW_DOCS":{
//       return {...state,
//         data:payload}

//     }

//     default:{
//         return state
        
//     }

//   }
// }




// export const Modal = (state = defaultState.modal, { type, payload }) => {
  
//   switch (type) {

//          case "SET_VIEW_MODAL":{
// return {...state,active:payload.active,
// type:payload.type,
// id:payload.id,
// data:payload.data,
// tabScreen:payload.tabScreen
//  }
//           }

//     case "SET_MODAL_DATA":{

//           if (payload){
//            return {...state,
//   data:payload.results} 
//           }

// break;
//     }

//     default:{
//         return state
        
//     }

//   }
// }


// export const AccountModal = (state = defaultState.account_modal, { type, payload }) => {
  
//   switch (type) {

//          case "SET_ACCOUNT_MODAL":{
// return {...state,active:!state.active,
// type:payload.type,id:payload.id }
//           }

//     case "SET_ACCOUNT_MODAL_DATA":{

//           if (payload){
//            return {...state,
//   data:payload.results} 
//           }
//           break;

//     }

//     default:{
//         return state
        
//     }

//   }
// }


// export const iframeModal = (state = defaultState.iframe_modal, { type, payload }) => {
  
//   switch (type) {

//          case "SET_IFRAME_MODAL":{
// return {...state,active:!state.active,
// url:payload
//  }
//           }

//        default:{
//         return state
        
//     }

//   }
// }


// export const imageModal = (state = defaultState.image_modal, { type, payload }) => {
  
//   switch (type) {

//          case "SET_IMAGE_MODAL":{
// return {...state,active:!state.active,
// uri:payload.uri,name:payload.name 
// }
//           }

//     default:{
//         return state
        
//     }

//   }
// }


// export const knowledgeModal = (state = defaultState.knowledge_modal, { type, payload }) => {
  
//   switch (type) {

//          case "SET_KNOWLEDGE_MODAL":{
// return {...state,active:!state.active,
// name:payload.name 
// }
//           }

//     default:{
//         return state
        
//     }

//   }
// }




export default Collections


