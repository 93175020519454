


import Collections,{Prompts} from "./data"
// {Docs,Modal,DocRow,Edit,Map as modalMap,Account,AccountModal,
// 	imageModal,knowledgeModal,iframeModal,searchHistory} from "./data"
// //import User from "./user"


const reducer =  {
	Collections,
	Prompts
	// accounts:Account,
	// docs:Docs,
	// docRow:DocRow,
	// modal:Modal,
	// account_modal:AccountModal,
	// image_modal:imageModal,
	// knowledge_modal:knowledgeModal,
	// iframe_modal:iframeModal,
	// edit:Edit,
	// map:modalMap,
	// search_history:searchHistory

}


export default reducer
