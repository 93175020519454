import React from 'react'
import { Link } from 'react-router-dom'
import {
  Text,
  HStack,
  Pressable,
  VStack, Center,Tooltip
} from "native-base";

import {getUser} from '../helper/authentication'
import "../assets/styles/collection_view.css"
import "../Circle.css"

 

const Papps = ({papps,show_add=true,lengt=5}) => {


  return <VStack space={3}  > 

<div class="row ">

{show_add ?

  <div class="custom-col mb-4"    >
<VStack class="mb-4 "  style={{     height:'140px',
    "boxShadow": "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}} >
<Tooltip label="New Blank App" bg="blue.500" openDelay={100} _text={{color:'white'}} >
<Pressable  h='130px' mt={2}    >
<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M7 12L12 12M12 12L17 12M12 12V7M12 12L12 17" stroke="#15a883" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
</Pressable>
</Tooltip>

</VStack>

<div style={{marginTop:"8px",fontSize:'12px',fontWeight:'bold'}} class="text-dark  " > Blank</div>

</div>:<> </>

}




{papps.map((papp,key)=>(

 <div class="custom-col mb-4 "    >
<VStack class=" mb-4  " alignItems='center' justifyContent='center' style={{     height:'140px',
    "boxShadow": "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}} >
{papp.name}

</VStack>
<div style={{marginTop:"8px",fontSize:'12px',fontWeight:'bold'}} class="text-dark  " > {papp.name}</div>
</div>

) )}



</div>

</VStack> ;

}


export default Papps


