
const process = {
	"1":{"title":"Genre / Sub-Genre","content":"Choose Genre and Subgenre","type":"dropdown","data":"genresandsubgenres",'required':true},
	"2":{"title":" Generate Story Premise","content":"Choose Setting Preferences","type":"dropdown","data":"preferences",'required':true},
	"3":{"title":" Generate Story Premise","content":"Choose Character Preferences","type":"dropdown","data":"preferences",'required':true},
	"4":{"title":" Generate Story Premise","content":"Choose Plot Preferences","type":"dropdown","data":"preferences",'required':true},
	"5":{"title":" Generate Story Premise","content":"Choose Tone and Atmosphere","type":"dropdown","data":"preferences",'required':true},
	"6":{"title":" Generate Story Premise","content":"Choose Narrative Style","type":"dropdown","data":"preferences",'required':true},
	"7":{"title":" Generate Story Premise","content":"Choose Audience Preferences","type":"dropdown","data":"preferences",'required':true},
	"8":{"title":" Generate Story Premise","content":"Choose Miscellaneous Preferences","type":"dropdown","data":"preferences",'required':false},
	"9":{"title":"Generating Story premise","content":"Loading, please wait...","type":"prompt","output":"Story Premise"},
	"10":{"title":"Generating Back Cover Blub","content":"Loading, please wait...","type":"prompt","prompt": `I have a story premise that I'd like to expand into a short back cover blurb for my novel. The premise is: 'Title: "Echoes of the Last Kingdom"

In a post-apocalyptic world where the remnants of civilization cling to survival amidst the ruins of a once-great kingdom, alchemy and blood magic have become the keys to power and control. Within this chaotic landscape, a femme fatale named Lysandra navigates the treacherous paths of intrigue and survival with a stoic determination born of past tragedies.

Lysandra, a charismatic rogue with a mysterious past, finds herself embroiled in a web of secrets and betrayals as she seeks to unravel the truth behind the fall of the monarchy. Haunted by unrequited love and driven by a thirst for justice, she must confront her own identity crisis amidst the shifting sands of alliances and enemies.

As Lysandra delves deeper into the heart of darkness that shrouds the kingdom, she discovers a forbidden love that challenges everything she thought she knew. Alongside her unlikely allies, she must navigate perilous encounters with rival factions and ancient secrets guarded by the very fabric of reality itself.

But when a heist gone wrong plunges Lysandra into a time loop, she must confront her own forgotten past and the amnesia that shrouds her memories. Each repetition of the loop brings new revelations and dangers, blurring the lines between truth and illusion, until she faces the ultimate choice between justice and revenge.

Set against the backdrop of a world on the brink of oblivion, "Echoes of the Last Kingdom" weaves a tale of resilience and redemption, where the echoes of the past reverberate through the present, and the fate of the future hangs in the balance. With dragons soaring overhead and shadows lurking in every corner, Lysandra's journey will test not only her strength but also the bonds of love and loyalty that bind her to those she holds dear.'`,"output":"Back Cover Blurb"},
"11":{"title":"Set Story Outline","content":"Choose an outline to further structure your story to  serves as a roadmap, providing a framework for the plot, character development, and key events","type":"dropdown","data":"outline_options",'required':true,"name":f=>f["option_name"],"description":f=>f["description"]},
"12":{"title":"Specifies Desired Style and Tone","content":"Sets the mood, atmosphere, and writing style for the entire narrative.","type":"dropdown","data":"styles_options",'required':true,},
"13":{"title":"Generating Story Introduction","content":"Loading, please wait...","type":"prompt","prompt": `With these details "
				[Genre/Sub-Genre]:
					- Genre: Science Fiction
					- SubGenre: Post-Apocalyptic

				[Setting Preferences]:
					- Historical Era: Post-Apocalyptic, Alternate History, Parallel Universe
					- Locations: Wilderness, Small Town
					- Worldbuilding Elements: Alchemy, Blood Magic,Monarchy

				[Character Preferences]:
				- Gender: Male
				- Protagonist Type: Femme Fatale, Rogue
				- Character Traits: Charismatic, Stoic
				- Relationships: Unrequited Love, Forbidden Love

				[Plot Preferences]:
				- Conflict Type: Survival, Heist
				- Plot Twists: Time Loop, Amnesia
				- Themes: Identity Crisis, Justice vs. Revenge

				[Tone and Atmosphere]:
				- Mood: Uplifting, Bittersweet
				- Atmosphere: Claustrophobic
				- Pace: Leisurely, Heart-pounding

				[Narrative Style]:
				- vdaaa: Second Person
				- Writing Style: Witty
				- Narrative Structure: Rashomon Effect

				[Audience Preferences]:
				- Target Audience: New Adult Romance
				- Genre Expectations: Genre Revival
				- Sensitivity: Cultural Sensitivity

				[Miscellaneous Preferences]:
				- Length: Novella Series
				- Cultural Influences: Dragons

				[Story Outline]
				- Story Structure: A thorough outline following Save the Cat’s 15-Beat Plot Structure, guiding the story through key moments such as the opening image, catalyst, midpoint, and resolution.

				[Style and Tone Preferences]
				- Pacing: Moderate-paced
				- Language Complexity: Simple language
				- Narrative Voice: First-person
				- Emotional Depth: Moderately emotional
				- Writing Style: Descriptive
				- Dialogue Tone: Casual
				- Scene Description: Sparse and minimalist
				- Character Interaction: Dynamic and engaging


				[Story Premise]
				'Title: "Echoes of the Last Kingdom"
				In a post-apocalyptic world where the remnants of civilization cling to survival amidst the ruins of a once-great kingdom, alchemy and blood magic have become the keys to power and control. Within this chaotic landscape, a femme fatale named Lysandra navigates the treacherous paths of intrigue and survival with a stoic determination born of past tragedies.
				Lysandra, a charismatic rogue with a mysterious past, finds herself embroiled in a web of secrets and betrayals as she seeks to unravel the truth behind the fall of the monarchy. Haunted by unrequited love and driven by a thirst for justice, she must confront her own identity crisis amidst the shifting sands of alliances and enemies.
				As Lysandra delves deeper into the heart of darkness that shrouds the kingdom, she discovers a forbidden love that challenges everything she thought she knew. Alongside her unlikely allies, she must navigate perilous encounters with rival factions and ancient secrets guarded by the very fabric of reality itself.
				But when a heist gone wrong plunges Lysandra into a time loop, she must confront her own forgotten past and the amnesia that shrouds her memories. Each repetition of the loop brings new revelations and dangers, blurring the lines between truth and illusion, until she faces the ultimate choice between justice and revenge.
				Set against the backdrop of a world on the brink of oblivion, "Echoes of the Last Kingdom" weaves a tale of resilience and redemption, where the echoes of the past reverberate through the present, and the fate of the future hangs in the balance. With dragons soaring overhead and shadows lurking in every corner, Lysandra's journey will test not only her strength but also the bonds of love and loyalty that bind her to those she holds dear.
				   				" Craft a captivating introduction for a novel, drawing upon the above provided details and preferences. `,"output":"Story Premise"},
"14":{"title":"Generating Scene 1","content":"Loading, please wait...","type":"prompt","prompt": `With these details "
				[Genre/Sub-Genre]:
					- Genre: Science Fiction
					- SubGenre: Post-Apocalyptic

				[Setting Preferences]:
					- Historical Era: Post-Apocalyptic, Alternate History, Parallel Universe
					- Locations: Wilderness, Small Town
					- Worldbuilding Elements: Alchemy, Blood Magic,Monarchy

				[Character Preferences]:
				- Gender: Male
				- Protagonist Type: Femme Fatale, Rogue
				- Character Traits: Charismatic, Stoic
				- Relationships: Unrequited Love, Forbidden Love

				[Plot Preferences]:
				- Conflict Type: Survival, Heist
				- Plot Twists: Time Loop, Amnesia
				- Themes: Identity Crisis, Justice vs. Revenge

				[Tone and Atmosphere]:
				- Mood: Uplifting, Bittersweet
				- Atmosphere: Claustrophobic
				- Pace: Leisurely, Heart-pounding

				[Narrative Style]:
				- vdaaa: Second Person
				- Writing Style: Witty
				- Narrative Structure: Rashomon Effect

				[Audience Preferences]:
				- Target Audience: New Adult Romance
				- Genre Expectations: Genre Revival
				- Sensitivity: Cultural Sensitivity

				[Miscellaneous Preferences]:
				- Length: Novella Series
				- Cultural Influences: Dragons

				[Story Outline]
				- Story Structure: A thorough outline following Save the Cat’s 15-Beat Plot Structure, guiding the story through key moments such as the opening image, catalyst, midpoint, and resolution.

				[Style and Tone Preferences]
				- Pacing: Moderate-paced
				- Language Complexity: Simple language
				- Narrative Voice: First-person
				- Emotional Depth: Moderately emotional
				- Writing Style: Descriptive
				- Dialogue Tone: Casual
				- Scene Description: Sparse and minimalist
				- Character Interaction: Dynamic and engaging


				[Story Premise]
				'Title: "Echoes of the Last Kingdom"
				In a post-apocalyptic world where the remnants of civilization cling to survival amidst the ruins of a once-great kingdom, alchemy and blood magic have become the keys to power and control. Within this chaotic landscape, a femme fatale named Lysandra navigates the treacherous paths of intrigue and survival with a stoic determination born of past tragedies.
				Lysandra, a charismatic rogue with a mysterious past, finds herself embroiled in a web of secrets and betrayals as she seeks to unravel the truth behind the fall of the monarchy. Haunted by unrequited love and driven by a thirst for justice, she must confront her own identity crisis amidst the shifting sands of alliances and enemies.
				As Lysandra delves deeper into the heart of darkness that shrouds the kingdom, she discovers a forbidden love that challenges everything she thought she knew. Alongside her unlikely allies, she must navigate perilous encounters with rival factions and ancient secrets guarded by the very fabric of reality itself.
				But when a heist gone wrong plunges Lysandra into a time loop, she must confront her own forgotten past and the amnesia that shrouds her memories. Each repetition of the loop brings new revelations and dangers, blurring the lines between truth and illusion, until she faces the ultimate choice between justice and revenge.
				Set against the backdrop of a world on the brink of oblivion, "Echoes of the Last Kingdom" weaves a tale of resilience and redemption, where the echoes of the past reverberate through the present, and the fate of the future hangs in the balance. With dragons soaring overhead and shadows lurking in every corner, Lysandra's journey will test not only her strength but also the bonds of love and loyalty that bind her to those she holds dear.

				[Scenes]
				The first scene introduces Lysandra in a post-apocalyptic wilderness, where she stands atop a hill overlooking the remnants of civilization. Despite the desolation, she exudes determination and resilience, ready to embark on a mission. She enters a small town, navigating through its streets with ease, despite the wary glances of the townsfolk. Lysandra's destination is a tavern, where she seeks information about the fall of the monarchy, suspecting the involvement of blood magic.
				In the second scene, Lysandra enters the dimly lit tavern, encountering a grizzled bartender who reluctantly shares information about the monarchy's downfall, implicating blood magic. This revelation only fuels Lysandra's determination to uncover the truth. As she leaves the tavern, she carries with her newfound knowledge, setting the stage for further exploration into the mysteries of the post-apocalyptic world.

				   				" Generate the next scene for the novel, drawing upon the details from the previous scenes to maintain continuity in the story. Ensure that the new scene progresses the narrative forward, introducing further developments, conflicts, or resolutions as needed. Maintain consistency with established character traits, plot points, and settings to keep the story cohesive. If the story has reached its conclusion, return a message indicating the end of the narrative and cease scene generation.`,"output":"Scenes"},

}

 

export const generate_scenes = (state={})=>{

console.log(state)
return ` Summarize the following text: 

			"""${state.content}"""


			Summary requirements:
				- Length: ${ state.custom_summary_length ? "Custom": state.summary_length.value}  (Specifies how concise the summary should be. Choose from brief (Summarize the following text in 3-5 sentences. Include the key points while keeping the summary short and to the point.), moderate (Summarize the following text in 6-10 sentences. Provide a balanced summary that covers the main points in more detail.), detailed (Summarize the following text in 11-15 sentences. Include detailed explanations and capture the essence of the content thoroughly.), comprehensive (Summarize the following text in 16-20 sentences. Provide an extensive summary that covers all significant aspects of the text.), or custom (Summarize the following text into a ${{...state.custom_summary_length}.value} of words/sentences. Ensure the summary maintains the core message of the original content.).)

				- Focus: ${state.summary_focus.value} (Determines the structure of the summary. Choose from key themes (Summarize the following text by highlighting the key themes and main topics. Group related information under each theme.), bullet points (Create a summary of the following text in bullet points. Each bullet should represent a key takeaway or essential point.), or narrative style (Provide a narrative-style summary of the following text. Ensure the summary flows smoothly and captures the essence of the content in paragraph form.).)

				- Language: ${state.summary_language.value} (Defines the language for the summary. Choose auto-detect (Summarize the following text in its original language. Automatically detect the language and provide an accurate summary.), or specify a language code such as 'en' for English (Summarize the following text in [language]. Ensure that the summary retains the key points while being coherent in [language].).)


				- Summarization Model: ${state.summarization_model.value}   (Selects the summarization approach. Options are extractive (Generate an extractive summary of the following text. Select and compile the most important sentences without altering the original language.), abstractive (Provide a hybrid summary of the following text, combining extractive and abstractive techniques to create a comprehensive summary.), or hybrid (Provide a hybrid summary of the following text, combining extractive and abstractive techniques to create a comprehensive summary.).)

				- Tone/Style: ${state.summary_tone_style.value}  (Sets the tone and style of the summary. Choose from formal (Summarize the following text in a formal tone. Ensure the summary is suitable for professional or technical contexts. (For formal or technical documents.), casual (Summarize the following text in a casual, conversational tone. Make the summary easy to read and engaging. For blogs, social media, etc.), technical (Provide a technically accurate summary of the following text. Use precise language and ensure that the summary reflects the technical nature of the content. For content that needs precise, technical language.), Dumb-Down (For simplified, easy-to-understand versions, for kids), persuasive (Create a persuasive summary of the following text. Highlight the key arguments and ensure the summary convinces the reader of the main points. For marketing or opinion pieces.), or descriptive (Summarize the following text in a descriptive style. Include detailed explanations and descriptions where necessary to convey the full meaning of the content. For educational content or reports.).)

		
			Important Notes:
				Format the result using clear and organized sections using html tags. Ensure the result is structured with appropriate headings and subheadings, making it easy to read and understand. Follow this outline:
					1. Title: A concise and informative title.
					2. Introduction: A brief introduction that introduces the topic and captures the reader’s attention.
					3. Main Sections: Break down the result into main sections, each with a clear heading. Each section should cover a specific point or subtopic.
					4. Subheadings: Use subheadings to further divide complex sections into smaller, digestible parts.
					5. Formatting: Use bullet points, numbered lists, or bold text where necessary to emphasize important points or lists.
					6. Make sure to maintain consistency in the formatting and use appropriate spacing, new lines and bold letters to improve readability.
				`

}






export default process;