import React from 'react'
import { Link } from 'react-router-dom'
import {
  Text,
  HStack,
  Pressable,
  VStack, Center,Tooltip,Input,Menu,Checkbox,Select
} from "native-base";

import {getUser} from '../helper/authentication'
import "../assets/styles/collection_view.css"
import "../Circle.css"
import "../Table.css"
import { TabView, SceneMap ,TabBar} from 'react-native-tab-view';


const BodyContext = ({collection}) => {

const [variables,setBodyContext] = React.useState([
{'variable':'baseUrl','initialValue':'','currentValue':'https://postman-echo.com','id':generateUniqueId()},
])
   const [shouldOverlapWithTrigger2] = React.useState(false);
  const [position, setPosition] = React.useState("auto");
  const [search, setSearch] = React.useState("");
  const [content, setContent] = React.useState("");
  const [engine, setEngine] = React.useState("");


function generateUniqueId() {
  return Math.random().toString(36).substr(2, 16); // Generates a random 9-character alphanumeric string
}




const PlainText = () => <VStack space={3} py={4} p={1}  h='80vh' overflowX='hidden'  >

    <textarea autofocus placeholder="Paste Plain Text" className='p-3' style={{width:'100%',height:'60vh' ,background:'#eeeeee4a', 
    borderRadius:'5px',
          fontSize:'.8em'}}
            defaultValue={content}
            onChange={(e) => {
            	setContent(e.target.value)
            }}
          />

</VStack> ;



const Documents = () => <VStack m="4">
 
 <label for="images" class="drop-container" id="dropcontainer">
  <span class="drop-title">Drop files here</span>
  or
  <input type="file" id="images" accept=".pdf,.doc,.docx,.txt,.rtf" multiple required />
</label>

  </VStack>;



const SearchQuery = () =>  <VStack mx="4" my={6} space={3}> 
<Text color='blue.400'> Query Value * </Text>
   <Input autofocus placeholder="Provide query value or ${variable name}"  bg="teal.100"  color="coolGray.900"   h={'43px'} 
     overflow="none"  borderColor='silver'    
    fontSize='14px'   borderRadius='10px'    
    InputLeftElement={
        <svg height="34px" width="34px" className="mx-2"  viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M11.132 9.71395C10.139 11.2496 10.3328 13.2665 11.6 14.585C12.8468 15.885 14.8527 16.0883 16.335 15.065C16.6466 14.8505 16.9244 14.5906 17.159 14.294C17.3897 14.0023 17.5773 13.679 17.716 13.334C18.0006 12.6253 18.0742 11.8495 17.928 11.1C17.7841 10.3573 17.4268 9.67277 16.9 9.12995C16.3811 8.59347 15.7128 8.22552 14.982 8.07395C14.2541 7.92522 13.4982 8.00197 12.815 8.29395C12.1254 8.58951 11.5394 9.08388 11.132 9.71395Z" stroke="#5c5c5c" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M17.5986 13.6868C17.2639 13.4428 16.7947 13.5165 16.5508 13.8513C16.3069 14.1861 16.3806 14.6552 16.7154 14.8991L17.5986 13.6868ZM19.0584 16.6061C19.3931 16.85 19.8623 16.7764 20.1062 16.4416C20.3501 16.1068 20.2764 15.6377 19.9416 15.3938L19.0584 16.6061ZM7.5 12.7499C7.91421 12.7499 8.25 12.4142 8.25 11.9999C8.25 11.5857 7.91421 11.2499 7.5 11.2499V12.7499ZM5.5 11.2499C5.08579 11.2499 4.75 11.5857 4.75 11.9999C4.75 12.4142 5.08579 12.7499 5.5 12.7499V11.2499ZM7.5 15.7499C7.91421 15.7499 8.25 15.4142 8.25 14.9999C8.25 14.5857 7.91421 14.2499 7.5 14.2499V15.7499ZM5.5 14.2499C5.08579 14.2499 4.75 14.5857 4.75 14.9999C4.75 15.4142 5.08579 15.7499 5.5 15.7499V14.2499ZM8.5 9.74994C8.91421 9.74994 9.25 9.41415 9.25 8.99994C9.25 8.58573 8.91421 8.24994 8.5 8.24994V9.74994ZM5.5 8.24994C5.08579 8.24994 4.75 8.58573 4.75 8.99994C4.75 9.41415 5.08579 9.74994 5.5 9.74994V8.24994ZM16.7154 14.8991L19.0584 16.6061L19.9416 15.3938L17.5986 13.6868L16.7154 14.8991ZM7.5 11.2499H5.5V12.7499H7.5V11.2499ZM7.5 14.2499H5.5V15.7499H7.5V14.2499ZM8.5 8.24994H5.5V9.74994H8.5V8.24994Z" fill="#5c5c5c"></path> </g></svg> }
        />


<HStack justifyContent='space-between' >  

  <VStack  space={3} w='49%' my={4} > 
<Text color='blue.400'> Search Engine * </Text>

    <Select style={{color:'coolGray.600'}}   accessibilityLabel="Choose Engine" placeholder="Choose Engine" _selectedItem={{
        bg: "coolGray.600", color:'coolGray.800'
      }} mt={1} onValueChange={itemValue => localStorage.setItem("search_engine",itemValue)}>
          <Select.Item label="Google" value="Google" />
          <Select.Item label="DuckDuckGo" value="DuckDuckGo" />
          <Select.Item label="Bing" value="Bing" />
        </Select>



</VStack>

  <VStack  space={3} w='49%' my={4} > 
<Text color='blue.400'> Schedule * </Text>

    <Select style={{color:'coolGray.600'}}   accessibilityLabel="Choose Engine" placeholder="Choose Engine" _selectedItem={{
        bg: "coolGray.600", color:'coolGray.800'
      }} mt={1} onValueChange={itemValue => localStorage.setItem("Schedule",itemValue)}>
          <Select.Item label="Every Time" value="Every Time" />
          <Select.Item label="Once" value="Once" />
        </Select>



</VStack>



</HStack>

  </VStack> 

const initialLayout = {
 
};
const renderScene = SceneMap({
  first: PlainText,
  second: Documents,
  third: SearchQuery
});

function BodyContextTabs({}) {

  const [index, setIndex] = React.useState(0);
  const [routes] = React.useState([{
    key: 'first',
    title: 'Plain Text'
  }, {
    key: 'second',
    title: 'Documents'
  }, {
    key: 'third',
    title: 'Search Query'
  } ]);


 const renderTabBar = props => ( 
  <TabBar  
    {...props}

      renderLabel={({ route, focused, color }) => (
    <Text   style={{ color:'gray', margin: 1}}>
     <HStack my={0} py={0} alignItems='center' justifyContent='center' space={1} > {route.title} </HStack>
    </Text>
)}

    indicatorStyle={{ backgroundColor: 'gray',marginRight:'0px'}}
    style={{ backgroundColor: '#eeea' }}
  />
);

    return <VStack   > <TabView navigationState={{
    index,
    routes
  }}  renderTabBar={renderTabBar} renderScene={renderScene}   onIndexChange={setIndex} 
 
   /> </VStack> ;
}




  return <VStack space={4} mt={4} > 
 
 <BodyContextTabs />


</VStack> ;

}


export default BodyContext


