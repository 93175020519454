import React from 'react'
import { Link } from 'react-router-dom'
import {
  Text,
  HStack,
  Pressable,
  VStack,Divider,Input,Badge,Box,Center,useColorModeValue,Menu,Heading,Button,Flex
} from "native-base";

import {getUser} from '../helper/authentication'
import { TabView, SceneMap ,TabBar} from 'react-native-tab-view';
import "../assets/styles/collection_view.css"
import "../assets/styles/prompt_view.css"
import "../Circle.css"
import PApps from './web_apps'
import Variables from './variables'
import BodyContext from './BodyContext'
import EditablePre from './editable_pre'
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
 


const Prompt = ({prompt})=>{


const [details,setDetails] = React.useState({}) 
const [openCol,setOpenCol] = React.useState()
   const [shouldOverlapWithTrigger2] = React.useState(false);
  const [position, setPosition] = React.useState("auto");

 

const getDetails = ()=> prompt


React.useState(()=>{

setDetails(getDetails())

},[prompt.id ])



 


function onChange(newValue) {
  console.log("change", newValue);
}

	return (<VStack minWidth='80vw' px={1}    >


<div class="flex-row2">

<div class='prompt_code' >


<div class='prompt_editor' >

<AceEditor
    mode="java"
    theme="github"
    onChange={onChange} 
    defaultValue={prompt.prompt}
    name="UNIQUE_ID_OF_DIV"
    editorProps={{ $blockScrolling: true }}
        width="100%"
    height="100%"
    fontSize="14px"
    enableBasicAutocompletion
    style={{lineHeight:'1.3em'}}

  />




</div>

<div class='prompt_output'  >

<div style={{display: "flex", borderBottom:"1px solid #eee"}} >
   <a  onclick="run_clear()"   class="  btn   btn-sm  mt-0 mb-0 " style={{
   	textTransform: "capitalize",width:"136px",color: "#f44336", border:"0px solid", 
   	boxShadow: "none", paddingTop: "10px"}}> 
   <svg preserveAspectRatio="xMidYMin" width="16" height="16" style={{fontSize:"16px",rotate:"0deg",marginRight:"3px"}}   fill="#c81414" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" stroke="#c81414"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M896 512c0-212.077-171.921-384-384-384-212.077 0-384 171.923-384 384 0 212.079 171.923 384 384 384 212.079 0 384-171.921 384-384zM579.883 398.863c12.497-12.497 32.759-12.497 45.257 0 12.493 12.497 12.493 32.757 0 45.254L557.257 512l67.878 67.883c12.497 12.497 12.497 32.759 0 45.257-12.493 12.493-32.755 12.493-45.252 0L512 557.257l-67.883 67.883c-12.497 12.493-32.757 12.493-45.254 0-12.497-12.497-12.497-32.759 0-45.257L466.744 512l-67.881-67.883c-12.497-12.497-12.497-32.758 0-45.254s32.757-12.497 45.254 0L512 466.744l67.883-67.881z"></path></g></svg>
             Clear </a>



              <div class="input-group">
     

            <input type="text" id="message-input" class="form-control" placeholder="Send a message ..." 
            style={{borderRadius: "0px",background: "#f3f3f3a8"}} />
            
            <div class="input-group-append" style={{
            	padding: "4px",
    paddingLeft: "10px",
    paddingRight: "10px",
    border: "1px solid #cbcbcb",
    background: "white",
    borderTopRightRadius: "7px",
    borderBottomRightRadius: "7px"
}} >
                    <i class="fa fa-caret-down"></i>  
            </div>


        </div>


 <a onclick="run_prompt()" id='send-button1'   class="  btn   btn-sm  mt-0 mb-0 " style={{
 	textTransform: "capitalize",width:"136px",color: "#009612", border:"0px solid",
 	 boxShadow: "none", paddingTop: "10px"}}> 
   <svg preserveAspectRatio="xMidYMin" width="16" height="16" style={{fontSize:"16px",rotate:"0deg",marginRight:"3px"}}   viewBox="0 0 24 24" fill="#009612" xmlns="http://www.w3.org/2000/svg" stroke="#009612"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M20.4086 9.35258C22.5305 10.5065 22.5305 13.4935 20.4086 14.6474L7.59662 21.6145C5.53435 22.736 3 21.2763 3 18.9671L3 5.0329C3 2.72368 5.53435 1.26402 7.59661 2.38548L20.4086 9.35258Z" stroke="#009612" stroke-width="1.5"></path> </g></svg>
             Run </a>


 <a id='send-button2' class="  btn   btn-sm  mt-0 mb-0 " style={{ textTransform: "capitalize",width:"136px",
 color: "#009612", border:"0px solid", boxShadow: "none", paddingTop: "10px",display: "none"}}> 
  <svg   style={{margin:"auto",background:"#fff",display:"block"}} width="28px" height="28px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
<circle cx="84" cy="50" r="10" fill="#e15b64">
    <animate attributeName="r" repeatCount="indefinite" dur="0.25s" calcMode="spline" keyTimes="0;1" values="10;0" keySplines="0 0.5 0.5 1" begin="0s"></animate>
    <animate attributeName="fill" repeatCount="indefinite" dur="1s" calcMode="discrete" keyTimes="0;0.25;0.5;0.75;1" values="#e15b64;#abbd81;#f8b26a;#f47e60;#e15b64" begin="0s"></animate>
</circle><circle cx="16" cy="50" r="10" fill="#e15b64">
  <animate attributeName="r" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="0s"></animate>
  <animate attributeName="cx" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="0s"></animate>
</circle><circle cx="50" cy="50" r="10" fill="#f47e60">
  <animate attributeName="r" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.25s"></animate>
  <animate attributeName="cx" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.25s"></animate>
</circle><circle cx="84" cy="50" r="10" fill="#f8b26a">
  <animate attributeName="r" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.5s"></animate>
  <animate attributeName="cx" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.5s"></animate>
</circle><circle cx="16" cy="50" r="10" fill="#abbd81">
  <animate attributeName="r" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.75s"></animate>
  <animate attributeName="cx" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.75s"></animate>
</circle>
</svg> </a>





        </div>

<div>

 

 <div id="chat-container"    style={{display: "flex",justifyContent: "center",alignItems: "center",
  flexDirection:"column"}} > 
   <svg fill="#eee" width="64px" height="64px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" stroke="#eee"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M520.741 163.801a10.234 10.234 0 00-3.406-3.406c-4.827-2.946-11.129-1.421-14.075 3.406L80.258 856.874a10.236 10.236 0 00-1.499 5.335c0 5.655 4.585 10.24 10.24 10.24h846.004c1.882 0 3.728-.519 5.335-1.499 4.827-2.946 6.352-9.248 3.406-14.075L520.742 163.802zm43.703-26.674L987.446 830.2c17.678 28.964 8.528 66.774-20.436 84.452a61.445 61.445 0 01-32.008 8.996H88.998c-33.932 0-61.44-27.508-61.44-61.44a61.445 61.445 0 018.996-32.008l423.002-693.073c17.678-28.964 55.488-38.113 84.452-20.436a61.438 61.438 0 0120.436 20.436zM512 778.24c22.622 0 40.96-18.338 40.96-40.96s-18.338-40.96-40.96-40.96-40.96 18.338-40.96 40.96 18.338 40.96 40.96 40.96zm0-440.32c-22.622 0-40.96 18.338-40.96 40.96v225.28c0 22.622 18.338 40.96 40.96 40.96s40.96-18.338 40.96-40.96V378.88c0-22.622-18.338-40.96-40.96-40.96z"></path></g></svg>
   <p class="text-secondary" > Empty output </p>



</div>
</div>
</div>


</div>


<div class='prompt_details py-2'>
        <h5 class="text-center font-weight-bold " style={{color:"black"}} >Model PM(s)</h5>

    <hr   />

 
 


</div>



</div>






</VStack>)
}


export default Prompt

