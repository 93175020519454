import React from 'react'
import { Link } from 'react-router-dom'
import {
  Text,
  HStack,
  Pressable,
  VStack,Divider,Input,Badge
} from "native-base";

import {getUser} from '../helper/authentication'
import {
    useDispatch, useSelector
} from 'react-redux';




 

const Prompt = ({collection,openCol,setOpenCol=f=>f})=>{


const [prompts,setPrompts] = React.useState([]) 

const defaultPrompts = useSelector(state => state.Prompts.data)


const getPrompts = ()=> defaultPrompts.filter(e=>e.collection == collection)


React.useState(()=>{
setPrompts(getPrompts())
},[collection])



const getDirIcon = (col)=>{
return <Badge colorScheme="info" bg='none'  _text={{fontSize:'11px',color:'yellow.400',fontWeight:'bol'}} py={0} w='50px' >{col && String(col.llm).slice(0,6) }</Badge>

}


	return (<VStack w='100%'    >

{prompts.map((col,key)=>(<VStack>

<Pressable style={{borderLeft:openCol ? '1px solid #d8d6d6':""}} bg={col===openCol ? "blue.100":""}  px={1} py={1.5}  _hover={{bg:"blue.100"}} onPress={e=>{
  setOpenCol(col)
}}  key={key}><HStack alignItems='center' overflow='hidden' >  {getDirIcon(col)} <Text color='coolGray.900' 
 style={{fontSize:'12px'}} > {col.name} </Text> </HStack> 
</Pressable> 
</VStack>
  ))}
 
</VStack>)
}


export default Prompt