



export const addCollection = (data) => async (dispatch) => {

	dispatch({
  type: 'SET_COLLECTION',
  payload:data
})

}


