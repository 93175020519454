import React from 'react'
import { Link } from 'react-router-dom'
import {
  Box,
  Text,
  HStack,
  Heading,
  Pressable,
  VStack,Divider,Input,Badge,Center,Menu,Button,Flex
} from "native-base";
import SideMenu from '../side_menu'
import Collections from '../collections'
import CollectionView from '../CollectionView'
import PromptView from '../PromptView'
import {getUser} from '../../helper/authentication'



const defaultPrompt = {'name':"API Key Check",id:"124",type:'Auto',collection:"124",llm:"GPT",model:"Turbo-3",prompt: ` // Enter New Prompt `}



export default function HomePage({sideMenu,setSideMenu}) {

const [collectionView,setCollectionView] = React.useState()
const [openPrompt,setOpenPrompt] = React.useState()
    const [shouldOverlapWithTrigger2] = React.useState(false);
  const [position, setPosition] = React.useState("auto");


function generateUniqueId() {
  return Math.random().toString(36).substr(2, 16); // Generates a random 9-character alphanumeric string
}


const addPrompt = ()=>{
    defaultPrompt['id'] = generateUniqueId()+'-'+getUser().Username
    defaultPrompt['collection'] = collectionView['id']
    defaultPrompt['name'] = "Enter name"
setOpenPrompt(defaultPrompt)
}

    return (
        <HStack w='100%' >

        <VStack    w='405px'   >
        <HStack    > 

  <HStack    style={{borderRight:"1px solid silver"}} py={6}  > 
  <VStack w='100%' justifyContent='space-between'  >
<SideMenu  sideMenu={sideMenu} setSideMenu={setSideMenu}  />
<Pressable> <svg width="24px" height="24px" viewBox="0 0 24 24" className="mx-1" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M11 5V19M6 8H8M6 11H8M6 14H8M6.2 19H17.8C18.9201 19 19.4802 19 19.908 18.782C20.2843 18.5903 20.5903 18.2843 20.782 17.908C21 17.4802 21 16.9201 21 15.8V8.2C21 7.0799 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V15.8C3 16.9201 3 17.4802 3.21799 17.908C3.40973 18.2843 3.71569 18.5903 4.09202 18.782C4.51984 19 5.07989 19 6.2 19Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg> </Pressable>
</VStack>
         </HStack>



  <HStack w='100%'   > 

{
    sideMenu === 'Collections' ?
    <Collections addPrompt={addPrompt} sideMenu={sideMenu} setCollectionView={e=>{
        setOpenPrompt()
        setCollectionView(e)
    }}
         collectionView={collectionView} 
    openPrompt={openPrompt} setOpenPrompt={setOpenPrompt} />:
    <VStack>
{sideMenu}
    </VStack>

}
 
         </HStack>


         </HStack>

        </VStack>


    <VStack maxWidth={{sm:'75vw'}}  bg='white'   >


 <HStack px={3} w='90%' h='41px'   style={{borderBottom:' 1px solid #eee'}} justifyContent='space-between' alignItems='center' py={'12px'} >
<Text color='coolGray.600' style={{fontWeight:'bold'}} >{ Object.assign({},openPrompt).name || Object.assign({},collectionView).name } </Text>

<HStack space={1} alignItems='center'   >
 <Button onPress={addPrompt} isDisabled={!collectionView} h="31px" mr='4' bg='coolGray.200' _text={{color:'coolGray.500'}} _hover={{backgroundColor:'coolGray.400'}}   leftIcon={<svg width="20px" height="20px"  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M7 12L12 12M12 12L17 12M12 12V7M12 12L12 17" stroke="#15a883" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>}>
        Add Prompt
      </Button>

<HStack alignItems='center' overflow='hidden'  space={1}  >  <svg  width="20px" height="20px"  viewBox="-0.5 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M13.47 4.13998C12.74 4.35998 12.28 5.96 12.09 7.91C6.77997 7.91 2 13.4802 2 20.0802C4.19 14.0802 8.99995 12.45 12.14 12.45C12.34 14.21 12.79 15.6202 13.47 15.8202C15.57 16.4302 22 12.4401 22 9.98006C22 7.52006 15.57 3.52998 13.47 4.13998Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>  <Text color='coolGray.900' _text={{fontSize:'10px'}} > Share </Text> </HStack>
<span style={{color:'#eee'}} >|</span><HStack alignItems='center' overflow='hidden' space={1}  >  <svg  width="18px" height="18px"  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M15.0007 12C15.0007 13.6569 13.6576 15 12.0007 15C10.3439 15 9.00073 13.6569 9.00073 12C9.00073 10.3431 10.3439 9 12.0007 9C13.6576 9 15.0007 10.3431 15.0007 12Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M12.0012 5C7.52354 5 3.73326 7.94288 2.45898 12C3.73324 16.0571 7.52354 19 12.0012 19C16.4788 19 20.2691 16.0571 21.5434 12C20.2691 7.94291 16.4788 5 12.0012 5Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>  <Text color='coolGray.900' _text={{fontSize:'10px'}} > 0 </Text> </HStack>
<span style={{color:'#eee'}} >|</span><HStack alignItems='center' overflow='hidden' space={1}  >  <svg  width="16px" height="16px"  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M18.1716 1C18.702 1 19.2107 1.21071 19.5858 1.58579L22.4142 4.41421C22.7893 4.78929 23 5.29799 23 5.82843V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H18.1716ZM4 3C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21L5 21L5 15C5 13.3431 6.34315 12 8 12L16 12C17.6569 12 19 13.3431 19 15V21H20C20.5523 21 21 20.5523 21 20V6.82843C21 6.29799 20.7893 5.78929 20.4142 5.41421L18.5858 3.58579C18.2107 3.21071 17.702 3 17.1716 3H17V5C17 6.65685 15.6569 8 14 8H10C8.34315 8 7 6.65685 7 5V3H4ZM17 21V15C17 14.4477 16.5523 14 16 14L8 14C7.44772 14 7 14.4477 7 15L7 21L17 21ZM9 3H15V5C15 5.55228 14.5523 6 14 6H10C9.44772 6 9 5.55228 9 5V3Z" fill="#0F0F0F"></path> </g></svg> <Text color='coolGray.900' _text={{fontSize:'10px'}} > Save </Text> </HStack>
<span style={{color:'#eee'}} >|</span><Menu w='120px' px={1}   bg='white'  shouldOverlapWithTrigger={shouldOverlapWithTrigger2}  
    placement={position == "auto" ? undefined : position} trigger={triggerProps => {
      return    <Pressable    {...triggerProps} >  
      <svg width="22px" height="22px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M7 12C7 13.1046 6.10457 14 5 14C3.89543 14 3 13.1046 3 12C3 10.8954 3.89543 10 5 10C6.10457 10 7 10.8954 7 12Z" fill="#1C274C"></path> <path d="M21 12C21 13.1046 20.1046 14 19 14C17.8954 14 17 13.1046 17 12C17 10.8954 17.8954 10 19 10C20.1046 10 21 10.8954 21 12Z" fill="#1C274C"></path> <path opacity="0.5" d="M14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z" fill="#1C274C"></path> </g></svg>
    </Pressable>
    }}>
    
        <Menu.Item  ><Pressable _hover={{color:'white'}}  _text={{textDecoration:'none',}} style={{fontSize:'13px'}} > Import </Pressable></Menu.Item> 
        <Menu.Item><Pressable  _hover={{color:'white'}}   _text={{textDecoration:'none'}}  style={{fontSize:'13px'}}> Export </Pressable></Menu.Item>
        <Menu.Item><Pressable  _hover={{color:'white'}}   _text={{textDecoration:'none'}}  style={{fontSize:'13px'}}> Duplicate</Pressable></Menu.Item>
        <Menu.Item><Pressable    _text={{textDecoration:'none'}}  style={{fontSize:'13px',color:'red'}}> Delete</Pressable></Menu.Item>
      </Menu> 


</HStack>


</HStack> 


    
    { openPrompt ?

<PromptView prompt={openPrompt} /> 
: collectionView ?

<CollectionView collection={collectionView} />
 :


<> </>}
        </VStack>
          
        </HStack>
    )
}
