import React from 'react'
import { Link } from 'react-router-dom'
import {
  Text,
  HStack,
  Pressable,
  VStack,Divider,Tooltip
} from "native-base";

import '../Circle.css'



const SideMenu = ({sideMenu,setSideMenu})=>{


	return (<VStack space={8} w='100%' h='89%' minHeight='87.5vh'  >
<Tooltip label="My Dashboard" bg="indigo.500" openDelay={100} _text={{color:'white'}} >
<Pressable onPress={e=>setSideMenu("Dashboard")} w='100%'><VStack alignItems='center' w='100%' space={1} >
<svg width="24px" height="24px" className={ sideMenu === "Dashboard"? `circle-0 bg-info p-1`:"hover-circle"}    fill="#000000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M21.7,7.3c-0.4-0.4-1-0.4-1.4,0L14,13.6L9.7,9.3C9.5,9.1,9.3,9,9,9C8.7,9,8.5,9.1,8.3,9.3l-6,6C2.1,15.5,2,15.7,2,16c0,0.6,0.4,1,1,1c0.3,0,0.5-0.1,0.7-0.3L9,11.4l4.3,4.3c0.1,0.1,0.2,0.2,0.3,0.2C13.7,16,13.9,16,14,16c0.2,0,0.5-0.1,0.6-0.3c0,0,0,0,0.1,0c0,0,0,0,0,0s0,0,0,0l7-7C22.1,8.3,22.1,7.7,21.7,7.3z"></path></g></svg>
<Text color='coolGray.900' > Dashboard </Text>
</VStack></Pressable>
</Tooltip>

<Tooltip label="View Collections" bg="indigo.500" openDelay={100} _text={{color:'white'}} >
<Pressable  onPress={e=>setSideMenu("Collections")} w='100%'><VStack alignItems='center' w='100%' space={1} >
<svg width="22px" height="22px" className={ sideMenu === "Collections"? `circle-0 bg-info p-1`:"hover-circles"}  viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="none"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g fill="#212121"> <path d="M3.05 4.75a.7.7 0 01.7-.7h3.5a.7.7 0 110 1.4h-3.5a.7.7 0 01-.7-.7zM3.75 7.05a.7.7 0 100 1.4h2.5a.7.7 0 100-1.4h-2.5z"></path> <path fill-rule="evenodd" d="M0 3.25A2.25 2.25 0 012.25 1h6.5c.883 0 1.648.51 2.016 1.25h.984c.698 0 1.3.409 1.582 1h.918c.966 0 1.75.784 1.75 1.75v6a1.75 1.75 0 01-1.75 1.75h-.918c-.281.591-.884 1-1.582 1h-.984A2.25 2.25 0 018.75 15h-6.5A2.25 2.25 0 010 12.75v-9.5zm13.5 8h.75a.25.25 0 00.25-.25V5a.25.25 0 00-.25-.25h-.75v6.5zm-2.5 1v-8.5h.75A.25.25 0 0112 4v8a.25.25 0 01-.25.25H11zM2.25 2.5a.75.75 0 00-.75.75v9.5c0 .414.336.75.75.75h6.5a.75.75 0 00.75-.75v-9.5a.75.75 0 00-.75-.75h-6.5z" clip-rule="evenodd"></path> </g> </g></svg>
<Text color='coolGray.900' > Collections </Text>
</VStack></Pressable>
</Tooltip>


<Tooltip label="My History" bg="indigo.500" openDelay={100} _text={{color:'white'}} >
<Pressable  onPress={e=>setSideMenu("History")} w='100%'><VStack alignItems='center' w='100%' space={1} >
<svg width="22px" height="22px" className={ sideMenu === "History"? `circle-0 bg-info p-1`:"hover-circle"}   viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M5.07868 5.06891C8.87402 1.27893 15.0437 1.31923 18.8622 5.13778C22.6824 8.95797 22.7211 15.1313 18.9262 18.9262C15.1312 22.7211 8.95793 22.6824 5.13774 18.8622C2.87389 16.5984 1.93904 13.5099 2.34047 10.5812C2.39672 10.1708 2.775 9.88377 3.18537 9.94002C3.59575 9.99627 3.88282 10.3745 3.82658 10.7849C3.4866 13.2652 4.27782 15.881 6.1984 17.8016C9.44288 21.0461 14.6664 21.0646 17.8655 17.8655C21.0646 14.6664 21.046 9.44292 17.8015 6.19844C14.5587 2.95561 9.33889 2.93539 6.13935 6.12957L6.88705 6.13333C7.30126 6.13541 7.63535 6.47288 7.63327 6.88709C7.63119 7.3013 7.29372 7.63539 6.87951 7.63331L4.33396 7.62052C3.92269 7.61845 3.58981 7.28556 3.58774 6.8743L3.57495 4.32874C3.57286 3.91454 3.90696 3.57707 4.32117 3.57498C4.73538 3.5729 5.07285 3.907 5.07493 4.32121L5.07868 5.06891ZM11.9999 7.24992C12.4141 7.24992 12.7499 7.58571 12.7499 7.99992V11.6893L15.0302 13.9696C15.3231 14.2625 15.3231 14.7374 15.0302 15.0302C14.7373 15.3231 14.2624 15.3231 13.9696 15.0302L11.2499 12.3106V7.99992C11.2499 7.58571 11.5857 7.24992 11.9999 7.24992Z" fill="#121212"></path> </g></svg>
<Text color='coolGray.900' > History </Text>
</VStack></Pressable>
</Tooltip>


<Tooltip label="Get API Key" bg="indigo.500" openDelay={100} _text={{color:'white'}} >
<Pressable  onPress={e=>setSideMenu("API")} w='100%'><VStack alignItems='center' w='100%' space={1} >
<svg width="22px" height="22px" className={ sideMenu === "API"? `circle-0 bg-info p-1`:"hover-circle"}   viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12.3212 10.6852L4 19L6 21M7 16L9 18M20 7.5C20 9.98528 17.9853 12 15.5 12C13.0147 12 11 9.98528 11 7.5C11 5.01472 13.0147 3 15.5 3C17.9853 3 20 5.01472 20 7.5Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
<Text color='coolGray.900' > API key </Text>
</VStack></Pressable>
</Tooltip>



</VStack>)
}


export default SideMenu